import styled from "@emotion/styled";
import { Button, ButtonProps } from "@mui/material";

//@ts-ignore
export const HoverBorderButton = styled(Button)<ButtonProps | any>(
  (props: any): any => ({
    borderRadius: 0,
    color: props.textColor || props.theme.colors.link,
    border: `1px solid ${props.borderColor || props.theme.colors.link}`,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      border: `1px solid ${props.borderHoverColor || props.theme.colors.link}`,
    },
    boxShadow: "none",
    textTransform: "none",
  })
);
