import { createSlice } from "@reduxjs/toolkit";

export interface UtilsState {
  modalContent: any;
  modalContentIsProgress: any;
}

const initialState: UtilsState = {
  modalContent: null,
  modalContentIsProgress: false,
};

export const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    setModalContent: (state, action) => {
      state.modalContent = action.payload;
    },
    setModalContentIsProgress: (state, action) => {
      state.modalContentIsProgress = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setModalContent, setModalContentIsProgress } =
  utilsSlice.actions;

export default utilsSlice.reducer;
