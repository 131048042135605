import React, { useEffect, useState } from "react";
import { useIsAuthenticated } from "@azure/msal-react";

interface Props {
  component: any;
}

const AuthGuard = (props: Props) => {
  const [status, setStatus] = useState(false);
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    setStatus(isAuthenticated);
  }, [isAuthenticated]);

  return status ? <>{props.component}</> : <></>;
};

export default AuthGuard;
