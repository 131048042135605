import React, { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import {
  flexRender,
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import Pagination from "../pagination/pagination";
import TableSortHeader from "../table-sort-header/table-sort-header";
import CustomLoader from "../custom-loader/custom-loader";
import { Box } from "@mui/material";

interface Props {
  columnConfig: any;
  data: any;
  handleRowClick: any;
  queryParams: any;
  updateParams: any;
  isLoading?: boolean;
  hidePagination?: boolean;
  hidePages?: boolean;
  totalItems: number;
  testId?: string;
}

let Table = styled.table({
  width: "100%",
  display: "table",
  borderCollapse: "collapse",
  color: "#333333",
});

let TableTh = styled.th({
  borderBottom: "1px solid #DFDFDF",
  padding: "10px 0",
  fontSize: "14px",
  textAlign: "initial",
});

let TableRow = styled.tr({
  borderBottom: "1px solid #DFDFDF",
  "&:nth-of-type(even)": {
    background: "#F8F8F8",
  },
  "&:nth-of-type(odd)": {
    background: "white",
  },
});

let TableCell = styled.td({
  padding: "10px 0",
  fontSize: "14px",
});

function CustomSummaryTable(props: Props) {
  const [params, setParams] = useState<{
    page: number;
    page_size: number;
    order_by: string;
  } | null>(null);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: props.data,
    columns: props.columnConfig,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
    },
    pageCount: 6,
    manualPagination: true,
    onPaginationChange: setPagination,
    debugTable: true,
  });

  /**
   * Update local query params based on "main" query params object
   */
  useEffect(() => {
    if (!props.queryParams) return;
    let p: any = {};
    p["page"] = +props.queryParams.page;
    p["page_size"] = +props.queryParams.page_size;
    p["order_by"] = props.queryParams.order_by;
    setParams(p);
    setPagination({
      pageIndex: p.page - 1,
      pageSize: p.page_size,
    });
  }, [props.queryParams]);

  /**
   * Update local query params variable
   */
  useEffect(() => {
    if (!params) return;
    if (
      props.queryParams.page !== params.page ||
      props.queryParams.page_size !== params.page_size ||
      props.queryParams.order_by !== params.order_by
    ) {
      props.updateParams({ page: params.page, order_by: params.order_by });
    }
  }, [params]);

  return (
    <>
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                let sortKey: any =
                  (header?.column?.columnDef?.meta as any)?.sortKey ||
                  header.id;
                return (
                  <TableTh
                    key={header.id}
                    style={{ minWidth: header.column.columnDef.minSize }}
                  >
                    <div>
                      {params ? (
                        <TableSortHeader
                          //@ts-ignore
                          label={header.column.columnDef.meta!["label"]}
                          orderBy={params.order_by}
                          id={sortKey}
                          onChangeOrdering={(order_by: string) => {
                            setParams({
                              ...params,
                              order_by: order_by,
                              page: 1,
                            });
                          }}
                          sortable={header.column.getCanSort()}
                          testId={
                            props.testId
                              ? `${props.testId}_table_header_${header.id}`
                              : ""
                          }
                          alignment={
                            //@ts-ignore
                            header.column.columnDef.meta![
                              "headerLabelAlignment"
                            ]
                          }
                        />
                      ) : (
                        <TableSortHeader
                          //@ts-ignore
                          label={header.column.columnDef.meta!["label"]}
                          orderBy={""}
                          id={sortKey}
                          onChangeOrdering={() => {}}
                          sortable={false}
                          testId={
                            props.testId
                              ? `${props.testId}_table_header_${header.id}`
                              : ""
                          }
                          alignment={
                            //@ts-ignore
                            header.column.columnDef.meta![
                              "headerLabelAlignment"
                            ]
                          }
                        />
                      )}
                      {/*{header.isPlaceholder*/}
                      {/*  ? null*/}
                      {/*  : flexRender(*/}
                      {/*      header.column.columnDef.header,*/}
                      {/*      header.getContext()*/}
                      {/*    )}*/}
                    </div>
                  </TableTh>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody data-testid={props.testId ? `${props.testId}_table` : ""}>
          <>
            {props.isLoading ? (
              <TableRow>
                <TableCell colSpan={table.options.columns.length}>
                  <Box
                    sx={{
                      // border: "1px solid red",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      paddingTop: 4,
                      paddingBottom: 4,
                    }}
                  >
                    <CustomLoader />
                    {/*{row.getVisibleCells().length}*/}
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {table.getRowModel().rows.map((row: any) => (
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map((cell: any, idx: any) => {
                      let renderRow =
                        !cell?.column?.columnDef?.meta?.enableRowSpan ||
                        (cell?.column?.columnDef?.meta?.enableRowSpan &&
                          row.index === 0);
                      if (!renderRow) return null;
                      return (
                        <TableCell
                          rowSpan={
                            cell?.column?.columnDef?.meta?.enableRowSpan
                              ? table.getRowModel().rows.length
                              : 1
                          }
                          //@ts-ignore
                          // rowSpan={row.id === 0 ? 0 : 4}
                          data-testid={
                            props.testId
                              ? `${props.testId}-table_row-${row.id}_${
                                  cell!.column!.id
                                }_value`
                              : ""
                          }
                          key={cell.id}
                          style={{ minWidth: cell.column.columnDef.minSize }}
                          onClick={(e) => {
                            props.handleRowClick(e, cell);
                          }}
                        >
                          <>
                            {/*{console.log(*/}
                            {/*  "table.getRowModel().rows.length",*/}
                            {/*  table.getRowModel().rows.length*/}
                            {/*)}*/}
                            {/*{console.log("row", row)}*/}
                            {/*{console.log("cell", cell)}*/}
                            {/*{console.log("cell", cell.column.id)}*/}
                            {/*{console.log("=========")}*/}
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </>
            )}
          </>
        </tbody>
      </Table>

      {params && !props.hidePagination && !props.isLoading && (
        <Pagination
          testId={props.testId ? `${props.testId}_pagination` : ""}
          hidePages={props.hidePages}
          currentPage={params.page}
          totalPage={
            Math.floor((props.totalItems || 0) / params.page_size) +
            (props.totalItems % params.page_size > 0 ? 1 : 0)
          }
          onPaginationChange={(data: any) => {
            setParams({ ...params, ...data });
          }}
        />
      )}
    </>
  );
}

export default CustomSummaryTable;
