import { createColumnHelper } from "@tanstack/react-table";
import { Box } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { BorderButton } from "../../styles/BorderButton";
import { format } from "date-fns";

type Record = {
  file_name: string;
  pk: number;
  updated_date_time: number;
  versions: any;
  actions: any;
};

const columnHelper = createColumnHelper<Record>();
export const unusedAddressesColumns = [
  columnHelper.accessor((row) => row.file_name, {
    id: "file_name",
    cell: (info) => {
      return <div style={{ wordBreak: "break-word" }}>{info.getValue()}</div>;
    },
    footer: (info) => info.column.id,
    minSize: 240,
    maxSize: 240,
    meta: {
      label: "File Name",
      enableRowSpan: true,
      headerBorderRight: true,
    },
    enableSorting: false,
  }),
  columnHelper.accessor("updated_date_time", {
    cell: (info) => {
      return (
        <Box>{format(new Date(info.getValue()), "dd-MM-yyyy hh:mm:ss a")}</Box>
      );
    },
    footer: (info) => info.column.id,
    minSize: 240,
    meta: {
      label: "Uploaded Date",
      enableRowSpan: true,
      headerBorderRight: true,
    },
    enableSorting: false,
  }),
  columnHelper.accessor("versions", {
    cell: (info) => {
      let rows = info.row.original["versions"];
      return (
        <Box display={"flex"} flexDirection={"column"}>
          {rows.map((x: any, i: number) => (
            <Box
              sx={{
                backgroundColor: i % 2 === 1 ? "#F1F1F1" : "white",
                padding: "10px",
              }}
            >
              {x.version_id}
            </Box>
          ))}
        </Box>
      );
    },
    footer: (info) => info.column.id,
    minSize: 140,
    meta: {
      label: "Version ID",
      noCellPadding: true,
    },
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.versions, {
    id: "address_count",
    cell: (info) => {
      let rows = info.row.original["versions"];
      return (
        <Box display={"flex"} flexDirection={"column"}>
          {rows.map((x: any, i: number) => (
            <Box
              sx={{
                backgroundColor: i % 2 === 1 ? "#F1F1F1" : "white",
                padding: "10px",
              }}
            >
              <NumericFormat
                value={x.address_count}
                thousandSeparator={","}
                displayType={"text"}
              />
            </Box>
          ))}
        </Box>
      );
    },
    footer: (info) => info.column.id,
    minSize: 140,
    meta: {
      label: "Quantity",
      headerBorderRight: true,
      noCellPadding: true,
    },
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.actions, {
    id: "actions",
    cell: (info) => {
      return (
        <Box display={"flex"} justifyContent={"center"}>
          <BorderButton
            type={"button"}
            sx={{ width: "60%" }}
            data-custom-action={"remove"}
            data-testid={"unused-files_remove_button"}
          >
            Remove
          </BorderButton>
        </Box>
      );
    },
    footer: (info) => info.column.id,
    minSize: 100,
    meta: {
      label: "Actions",
      enableRowSpan: true,
    },
    enableSorting: false,
  }),
];
