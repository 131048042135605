import styled from "@emotion/styled";
import { Button, ButtonProps, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import MainLogo from "../../assets/images/enru-logo.png";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../../styles/PrimaryButton";

let SignInContainer = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#003F34",
  height: "100vh",
  width: "100wh",
});

let LogoContainer = styled.img({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "80px",
  width: "100%",
  objectFit: "contain",
  margin: "50px 0",
});

let FormContainer = styled.form({
  height: "100%",
  width: "100%",
});

let SubmitButtonContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "50px 0",
});

let LoginContainer = styled(Grid)({
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  padding: "0 45px",
});

let ForgotPasswordLink = styled(Link)`
  text-transform: none;
  color: ${(props: any) => props.theme.colors.link};
  text-decoration: none;
`;

let EMAIL_REGX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const loginValidationSchema = yup.object({
  username: yup
    .string()
    .required("Required")
    .matches(EMAIL_REGX, "Wrong email address"),
  password: yup.string().required("Required"),
});

function SignInPage() {
  const [defaultValue, setDefaultValue] = useState<any>({
    username: "",
    password: "",
  });
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValue;
    }, [defaultValue]),
    resolver: yupResolver(loginValidationSchema),
  });

  useEffect(() => {
    if (!defaultValue) return;
    reset(defaultValue);
  }, [defaultValue]);
  const onSubmit = (data: any) => {};

  return (
    <SignInContainer container justifyContent={"center"}>
      <LoginContainer item xs={7} md={5} lg={3} xl={3}>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <LogoContainer src={MainLogo} />

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="username"
                render={({ field }) => (
                  <TextField
                    sx={{ width: "100%" }}
                    {...field}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    error={!!errors.hasOwnProperty("username")}
                    helperText={
                      errors?.username?.message
                        ? `${errors?.username?.message}`
                        : null
                    }
                    label="Email Address"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <TextField
                    sx={{ width: "100%" }}
                    {...field}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    error={!!errors.hasOwnProperty("password")}
                    helperText={
                      errors?.password?.message
                        ? `${errors?.password?.message}`
                        : null
                    }
                    label="Password"
                    type="password"
                  />
                )}
              />
            </Grid>
            <Grid item>
              <ForgotPasswordLink to={""}>Forgot Password</ForgotPasswordLink>
            </Grid>
          </Grid>

          <SubmitButtonContainer>
            <PrimaryButton type="submit" variant="contained">
              Sign In
            </PrimaryButton>
          </SubmitButtonContainer>
        </FormContainer>
      </LoginContainer>
    </SignInContainer>
  );
}

export default SignInPage;
