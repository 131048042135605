import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

let PaginationContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //margin-top: 40px;
`;

let PaginationArrow = styled(Box)<any>`
  width: 30px;
  padding: 0 10px;
  color: #b5b5b5;
  cursor: ${(props: any) => (props.disabled ? "not-allowed" : " pointer")};
  display: flex;
  justify-content: center;
  align-items: center;
`;

let PageNumber = styled(Box)<any>`
  width: 30px;
  text-align: center;
  color: ${(props: any) => (props.active ? "#777777" : "#B5B5B5")};
  cursor: ${(props: any) => (props.disabled ? "not-allowed" : " pointer")};
`;

interface Props {
  currentPage: number;
  totalPage: number;
  onPaginationChange: any;
  hidePages: any;
  testId: string;
}
interface PageObject {
  value: number | string;
  clickable: boolean;
}
function Pagination(props: Props) {
  const [currentPage, setCurrentPage] = useState<number>(() => {
    return +props.currentPage || 1;
  });
  const [pages, setPages] = useState<Array<PageObject> | null>(null);

  useEffect(() => {
    if (props.currentPage === currentPage) return;
    setCurrentPage(props.currentPage);
  }, [props.currentPage]);

  useEffect(() => {
    let pag: Array<PageObject> = createPagination(currentPage, props.totalPage);
    setPages(pag);
  }, [props.totalPage]);

  const createPagination = (current: number, total: number) => {
    if (total === 0) return [];
    if (total === 1) {
      return [{ value: 1, clickable: true }];
    }
    const center = [
        { value: current - 2, clickable: true },
        { value: current - 1, clickable: true },
        { value: current, clickable: true },
        { value: current + 1, clickable: true },
        { value: current + 2, clickable: true },
      ],
      filteredCenter: Array<PageObject> = center.filter(
        (p) => p.value > 1 && p.value < total
      ),
      includeThreeLeft = current === 5,
      includeThreeRight = current === total - 4,
      includeLeftDots = current > 5,
      includeRightDots = current < total - 4;

    if (includeThreeLeft) filteredCenter.unshift({ value: 2, clickable: true });
    if (includeThreeRight)
      filteredCenter.push({
        value: total - 1,
        clickable: true,
      });

    if (includeLeftDots)
      filteredCenter.unshift({ value: "...", clickable: false });
    if (includeRightDots)
      filteredCenter.push({ value: "...", clickable: false });

    return [
      { value: 1, clickable: true },
      ...filteredCenter,
      { value: total, clickable: true },
    ];
  };

  useEffect(() => {
    let pag: Array<PageObject> = createPagination(currentPage, props.totalPage);
    setPages(pag);
  }, []);

  useEffect(() => {
    let pag: Array<PageObject> = createPagination(currentPage, props.totalPage);
    setPages(pag);
  }, [currentPage]);

  const onPageClick = (page: number) => {
    setCurrentPage(page);
    props.onPaginationChange({ page: page });
  };

  return (
    <PaginationContainer sx={{ mt: 3 }}>
      {pages && !!pages.length && !props.hidePages && (
        <>
          <PaginationArrow
            data-testid={props.testId ? `${props.testId}_first-page` : ""}
            disabled={currentPage <= 1}
            onClick={() => {
              onPageClick(1);
            }}
          >
            <KeyboardDoubleArrowLeftIcon />
          </PaginationArrow>
          <PaginationArrow
            data-testid={props.testId ? `${props.testId}_previous-page` : ""}
            disabled={currentPage <= 1}
            onClick={() => {
              if (currentPage > 1) onPageClick(currentPage - 1);
            }}
          >
            <KeyboardArrowLeftIcon />
          </PaginationArrow>
          {/*{pages && !props.hidePages && (*/}
          <div style={{ display: "flex", flexDirection: "row" }}>
            {pages!.map((p: any, index: number) => {
              return (
                <PageNumber
                  key={index}
                  data-testid={
                    props.testId ? `${props.testId}_page-${p.value}` : ""
                  }
                  active={+p.value === +currentPage ? "true" : undefined}
                  disabled={!p.clickable}
                  onClick={() => {
                    if (!p.clickable) return;
                    onPageClick(p.value);
                  }}
                >
                  {p.value}
                </PageNumber>
              );
            })}
          </div>
          {/*)}*/}
          <PaginationArrow
            data-testid={props.testId ? `${props.testId}_next-page` : ""}
            disabled={currentPage >= props.totalPage}
            onClick={() => {
              if (currentPage < props.totalPage) onPageClick(currentPage + 1);
            }}
          >
            <KeyboardArrowRightIcon />
          </PaginationArrow>
          <PaginationArrow
            data-testid={props.testId ? `${props.testId}_last-page` : ""}
            disabled={currentPage >= props.totalPage}
            onClick={() => {
              onPageClick(props.totalPage);
            }}
          >
            <KeyboardDoubleArrowRightIcon />
          </PaginationArrow>
        </>
      )}
    </PaginationContainer>
  );
}

export default Pagination;
