import React, { useEffect, useState } from "react";
import useUser from "../hooks/useUser";
import { useNavigate } from "react-router-dom";

interface Props {
  component: any;
}

const UnauthGuard = (props: Props) => {
  // const [status, setStatus] = useState(false);
  const { user, userRequestDone } = useUser();
  const navigate = useNavigate();

  const [isUnauthenticated, setIsUnauthenticated] = useState(false);

  useEffect(() => {
    // setTimeout(() => {
    //   setIsUnauthenticated(true);
    // }, 1000);
  }, []);

  /**
   * Wait
   */
  useEffect(() => {
    if (!userRequestDone) return;
    if (user) {
      navigate("/overview");
    } else {
      setIsUnauthenticated(true);
    }
  }, [user, userRequestDone]);

  return isUnauthenticated ? <>{props.component}</> : <></>;
};

export default UnauthGuard;
