import { Box, keyframes } from "@mui/material";
import React, { useState } from "react";
import styled from "@emotion/styled";
import OuterImage from "../../assets/images/circle-loader-outer.svg";
import InnerImage from "../../assets/images/circle-loader-inner.svg";

interface Props {
  size?: number;
}

const rotateRight = () => keyframes`
    0% {
        transform : rotateZ(359deg) 
    }
    100% {
        transform : rotateZ(0deg)
    }
`;

const rotateLeft = () => keyframes`
    0% {
        transform : rotateZ(0deg) 
    }
    100% {
        transform : rotateZ(359deg)
    }
`;

const ImageContainerOuter = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  animation: ${rotateLeft()} 1.2s infinite;
`;

const ImageContainerInner = styled.img`
  position: absolute;
  width: 60%;
  height: 60%;
  top: 20%;
  left: 20%;
  animation: ${rotateRight()} 1.2s infinite;
`;

function CustomLoader({ size = 60 }: Props) {
  return (
    <Box
      sx={{
        position: "relative",
        height: size,
        width: size,
        userSelect: "none",
        display: "flex",
      }}
    >
      <ImageContainerOuter src={OuterImage} />
      <ImageContainerInner src={InnerImage} />
    </Box>
  );
}

export default CustomLoader;
