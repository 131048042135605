import styled from "@emotion/styled";
import {
  Box,
  Container,
  Grid,
  keyframes,
  Paper,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import { PageContainer } from "../../styles/PageContainer";
import UserManagementIcon from "../../assets/images/user-management-icon.svg";
import { getJobsStats } from "../../store/jobSlice";
import React, { useEffect } from "react";
import { format } from "date-fns";
import Loader from "../../assets/images/loader";
import { setModalContent } from "../../store/utilsSlice";
import CreatePool from "../../modals/create-pool-modal";
import GeneralStatusDot from "../../components/general-status-dot/general-status-dot";
import { ContentContainerInner } from "../main-layout/main-layout";
import CustomLoader from "../../components/custom-loader/custom-loader";
import { JobStatModel, StatusName } from "../../models/JobStatModel";
import { UserModel } from "../../models/UserModel";

let UserManagementIconContainer = styled.img({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100px",
  marginTop: "15px",
});

let TopBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props: any) => `1px solid ${props.theme.colors.lightGray}`};
`;
const rotateRight = () => keyframes`
    0% {
        transform: rotateZ(0deg)
    }
    100% {
        transform: rotateZ(359deg)
    }
`;

let RotateContainer = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${rotateRight()} ${(props: any) => (props.animated ? 2 : 0)}s
    linear infinite;
`;

function LandingPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loggedUser: UserModel | null = useSelector(
    (state: RootState) => state.user.loggedUser
  );
  const jobsStats: JobStatModel | null = useSelector(
    (state: RootState) => state.job.jobsStats
  );
  const loadingJobsStatsStatus = useSelector(
    (state: RootState) => state.job.loadingJobsStatsStatus
  );
  const modalContent = useSelector(
    (state: RootState) => state.utils.modalContent
  );

  useEffect(() => {
    dispatch(getJobsStats({}));
  }, []);

  const goToAdmin = () => {
    navigate("/users");
  };
  const goToOverview = () => {
    navigate("/overview");
  };

  const onClosePoolModal = (data: any) => {
    dispatch(setModalContent(null));
    if (data && data.success) {
      navigate("/summary");
    }
  };

  const createPool = () => {
    if (!modalContent) {
      dispatch(setModalContent(<CreatePool onClose={onClosePoolModal} />));
    }
  };

  return (
    <ContentContainerInner>
      <PageContainer sx={{ my: 4, mx: 2, p: 3 }}>
        <TopBox
          sx={{
            pb: 2,
          }}
        >
          <div>
            <Typography variant="h5">Welcome!</Typography>
            <Typography variant="subtitle2">
              Hello {!!loggedUser && loggedUser.name}!
            </Typography>
          </div>
        </TopBox>
        {jobsStats && (
          <Box sx={{}}>
            <Typography variant="h6" sx={{ margin: "20px 0" }}>
              Dashboard
            </Typography>
            <Container sx={{ padding: "0 !important" }}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={9}>
                  <Paper
                    // elevation={2}
                    onClick={goToOverview}
                    variant={"outlined"}
                    sx={{
                      height: "100%",
                      borderRadius: "6px",
                      cursor: "pointer",
                    }}
                  >
                    <Container
                      sx={{
                        padding: "20px 20px !important",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12} md={9}>
                          <Box>
                            <Typography
                              variant="h5"
                              sx={{ fontWeight: "bold", marginBottom: "10px" }}
                            >
                              Postal Optimization
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: "bold", marginBottom: "15px" }}
                            >
                              ABOUT THE APP
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Box
                            alignItems={"center"}
                            justifyContent={{ xs: "initial", md: "flex-end" }}
                            display={"flex"}
                            sx={{ padding: "20px 0" }}
                          ></Box>
                        </Grid>
                      </Grid>
                    </Container>
                    <Container
                      sx={{
                        padding: "10px 20px !important",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid xs={12} md={6} item>
                          <Typography
                            variant="body2"
                            fontWeight={"bold"}
                            sx={{ marginBottom: "20px" }}
                          >
                            LATEST JOB
                          </Typography>
                          <Grid container>
                            <Grid item xs={8}>
                              <Typography
                                variant="h6"
                                fontWeight={"bold"}
                                data-testid={"landing-page_latest-job_label"}
                              >
                                {jobsStats.latest_job?.name || "-"}
                              </Typography>
                              <Box sx={{ marginBottom: "20px" }}>
                                {jobsStats.latest_job?.status ? (
                                  <GeneralStatusDot
                                    color={jobsStats.latest_job?.color}
                                    label={jobsStats.latest_job?.status}
                                  />
                                ) : (
                                  "-"
                                )}
                              </Box>
                              <Typography variant={"subtitle1"}>
                                Start:{" "}
                                {jobsStats.latest_job?.start_date_time
                                  ? format(
                                      new Date(
                                        jobsStats.latest_job?.start_date_time
                                      ),
                                      "dd-MM-yyyy hh:mm:ss a"
                                    )
                                  : "-"}
                              </Typography>
                              <Typography variant={"subtitle1"}>
                                End:{" "}
                                {jobsStats.latest_job?.end_date_time
                                  ? format(
                                      new Date(
                                        jobsStats.latest_job?.end_date_time
                                      ),
                                      "dd-MM-yyyy hh:mm:ss a"
                                    )
                                  : "-"}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              {jobsStats.latest_job?.status && (
                                <RotateContainer
                                  animated={
                                    jobsStats.latest_job?.status !== "CANCELING"
                                  }
                                >
                                  <Loader
                                    color={
                                      jobsStats?.latest_job?.color || "#9c9c9c"
                                    }
                                  />
                                </RotateContainer>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid xs={12} md={6} item>
                          <Typography
                            variant="body2"
                            fontWeight={"bold"}
                            sx={{ marginBottom: "20px" }}
                          >
                            ALL JOBS
                          </Typography>
                          <Box
                            data-testid={"landing-page_all-jobs_status-summary"}
                          >
                            {Object.keys(jobsStats.aggregated_status_data).map(
                              (item: string, index: number) => {
                                return (
                                  <Container
                                    key={item}
                                    sx={{
                                      display: "flex",
                                      padding: "5px 0 !important",
                                    }}
                                  >
                                    <Box sx={{ marginRight: "10px" }}>
                                      {
                                        jobsStats.aggregated_status_data[item]
                                          ?.count
                                      }
                                    </Box>{" "}
                                    <GeneralStatusDot
                                      color={
                                        jobsStats.aggregated_status_data[item]
                                          ?.color
                                      }
                                      label={item}
                                    />
                                  </Container>
                                );
                              }
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Container>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Paper
                    // elevation={2}
                    variant={"outlined"}
                    sx={{
                      backgroundColor: "transparent",
                      height: "100%",
                      borderRadius: "6px",
                      cursor: "pointer",
                    }}
                    data-testid={"landing-page_user-list_link"}
                    onClick={goToAdmin}
                  >
                    <Container
                      maxWidth={false}
                      sx={{ height: "100%", padding: "10px !important" }}
                    >
                      <Box
                        sx={{
                          background: "#F6F6F6",
                          borderRadius: "6px",
                          position: "relative",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Container
                          sx={{
                            padding: "10px !important",
                            minHeight: "250px",
                          }}
                        >
                          <UserManagementIconContainer
                            src={UserManagementIcon}
                          />
                          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                            User Management
                          </Typography>
                          <Typography variant="body2">
                            Here You Can Manage App Users
                          </Typography>
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: "10px",
                              right: "10px",
                            }}
                          >
                            <Typography variant="body2" fontWeight={"bold"}>
                              Go To Admin Panel
                            </Typography>
                          </Box>
                        </Container>
                      </Box>
                    </Container>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Box>
        )}
        {loadingJobsStatsStatus === "loading" && !jobsStats && (
          <Box
            sx={{
              // border: "1px solid red",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <CustomLoader />
          </Box>
        )}
      </PageContainer>
    </ContentContainerInner>
  );
}

export default LandingPage;
