interface Props {
  color: string;
}

function Loader(props: Props) {
  return (
    <svg
      width="123"
      height="123"
      viewBox="0 0 123 123"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.7656 46.125C30.3806 46.4942 30.9956 46.6171 31.6106 46.6171C32.7177 46.6171 33.8248 46.0021 34.5627 45.0179C35.6698 43.1729 37.0227 41.5737 38.3756 39.975C39.7285 38.6221 39.6056 36.4079 38.2527 35.1779C36.8998 33.825 34.6856 33.9479 33.4556 35.3008C31.7335 37.1458 30.1348 39.2366 28.7813 41.4508C27.6747 43.05 28.1668 45.1408 29.7656 46.125Z"
        fill={props.color}
      />
      <path
        d="M48.8303 90.405C43.9103 88.1908 39.4824 84.87 36.2843 80.565C35.1772 79.0892 32.9635 78.72 31.4872 79.95C30.0114 81.0571 29.6422 83.2708 30.8722 84.7471C34.808 89.9129 40.0972 94.095 46.1242 96.8011C46.6163 97.0469 46.985 97.0469 47.4771 97.0469C48.83 97.0469 50.06 96.309 50.675 94.9561C51.4133 93.234 50.5521 91.1428 48.83 90.4049L48.8303 90.405Z"
        fill={props.color}
      />
      <path
        d="M30.8727 69.249C29.7656 65.1898 29.6427 60.8848 30.1348 56.826C30.3806 54.981 29.1506 53.2589 27.1827 53.0131C25.3377 52.7673 23.6156 53.9973 23.3698 55.9652C22.6319 61.0081 22.8777 66.051 24.2306 71.0942C24.5998 72.6934 26.0756 73.6771 27.5514 73.6771C27.7973 73.6771 28.1664 73.6771 28.4123 73.5542C30.2577 72.9388 31.3648 71.0938 30.8727 69.2488L30.8727 69.249Z"
        fill={props.color}
      />
      <path
        d="M48.5935 26.1221C50.3545 26.635 51.3663 28.4784 50.8529 30.2393C50.34 32.0003 48.4967 33.0121 46.7357 32.4992C44.9748 31.9859 43.963 30.1425 44.4763 28.3815C44.9892 26.6206 46.8326 25.6088 48.5935 26.1221Z"
        fill={props.color}
      />
      <path
        d="M61.3768 22.878C59.5318 22.878 57.9326 24.3538 57.9326 26.3222C57.9326 28.1672 59.4084 29.7664 61.3768 29.7664C78.8428 29.7664 93.1108 44.0344 93.1108 61.5004C93.1108 64.8212 92.6187 68.1425 91.5116 71.4633C90.8966 73.3083 91.8808 75.1533 93.7258 75.7683C94.095 75.8912 94.4637 75.8912 94.8329 75.8912C96.3087 75.8912 97.6621 75.0304 98.0308 73.5541C99.3837 69.6183 99.9987 65.5591 99.9987 61.5001C99.9987 40.2211 82.6557 22.8781 61.3767 22.8781L61.3768 22.878Z"
        fill={props.color}
      />
      <path
        d="M84.7468 82.902C78.7197 89.4212 70.2328 93.234 61.3768 93.234C59.5318 93.234 57.9326 94.7098 57.9326 96.6782C57.9326 98.5232 59.4084 100.122 61.3768 100.122C72.0778 100.122 82.5328 95.5716 89.7898 87.5764C91.0198 86.2235 91.0198 84.0093 89.544 82.7793C88.1907 81.4256 86.0998 81.5489 84.7469 82.9018L84.7468 82.902Z"
        fill={props.color}
      />
    </svg>
  );
}

export default Loader;
