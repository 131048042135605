import { useEffect, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

function useUser() {
  const { userProfile } = useSelector((state: RootState) => state.app);
  const [user, setUser] = useState<any>(null);
  const [userRequestDone, setUserRequestDone] = useState<any>(false);

  useEffect(() => {
    setUser(userProfile);
    if (userProfile) {
      setUserRequestDone(true);
    }
  }, [userProfile]);

  return { user, userRequestDone };
}

export default useUser;
