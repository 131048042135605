import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { PrimaryButton } from "../styles/PrimaryButton";
import CustomLoader from "../components/custom-loader/custom-loader";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";

let TopBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props: any) => `1px solid ${props.theme.colors.lightGray}`};
`;

let ModalContainer = styled(Box)({
  backgroundColor: "white",
});

interface Props {
  onClose: any;
  text: string;
  header: string;
}

export const ConfirmationModal = (props: Props) => {
  const isProgress = useSelector(
    (state: RootState) => state.utils.modalContentIsProgress
  );
  const onClose = (status: any) => {
    props.onClose(status);
  };

  const goBack = () => {
    onClose({ status: 0 });
  };
  const confirm = () => {
    onClose({ status: 1 });
  };
  return (
    <Dialog open={true} onClose={() => {}}>
      <ModalContainer sx={{ p: 3 }}>
        <TopBox
          sx={{
            pb: 2,
          }}
        >
          <div>
            <Typography variant="h5">{props.header}</Typography>
          </div>
          <div></div>
        </TopBox>
        <DialogContent>{props.text}</DialogContent>
        {/*<DialogActions>*/}
        <Box display={"flex"} justifyContent={"space-between"} sx={{ mt: 4 }}>
          <Box
            sx={{ width: "33%" }}
            display={"flex"}
            justifyContent={"flex-start"}
          >
            <Button onClick={goBack}>Go Back</Button>
          </Box>
          <Box sx={{ width: "33%" }} display={"flex"} justifyContent={"center"}>
            <PrimaryButton
              type="button"
              onClick={confirm}
              disabled={isProgress}
            >
              {isProgress && (
                <Box sx={{ mr: 3 }}>
                  <CustomLoader size={20} />
                </Box>
              )}
              Confirm
            </PrimaryButton>
          </Box>
          <Box
            sx={{ width: "33%" }}
            display={"flex"}
            justifyContent={"flex-end"}
          ></Box>
        </Box>
      </ModalContainer>
    </Dialog>
  );
};
export default ConfirmationModal;
