import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

let HeaderLabel = styled(Box)<any>`
  cursor: ${(props: any) => (props.sortable ? "pointer" : "initial")};
`;

interface Props {
  label: string | undefined;
  id: string | undefined;
  orderBy: string | undefined;
  sortable: boolean;
  alignment?: string;
  testId?: string;

  onChangeOrdering(order: string): any;
}

function TableSortHeader({
  label = "",
  orderBy,
  id,
  onChangeOrdering,
  sortable,
  alignment = "left",
  testId,
}: Props) {
  const [direction, setDirection] = useState<string | null>(null);
  const [field, setField] = useState<string | null>(null);
  useEffect(() => {
    if (orderBy != null) {
      setDirection(orderBy[0] === "-" ? "desc" : "asc");
      setField(orderBy.replace("-", ""));
    }
  }, [orderBy]);
  const onClick = () => {
    if (!sortable) return;
    if (orderBy != null && id != null) {
      if (field === id) {
        onChangeOrdering(orderBy[0] === "-" ? id : `-${id}`);
      } else {
        onChangeOrdering(id);
      }
    }
  };

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={alignment}
      textAlign={alignment as any}
      data-testid={`${testId}`}
    >
      <HeaderLabel
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        onClick={onClick}
        sortable={sortable ? "true" : undefined}
      >
        {label}
        {field === id && (
          <>
            {direction === "asc" ? (
              <ArrowDropUpIcon></ArrowDropUpIcon>
            ) : (
              <ArrowDropDownIcon></ArrowDropDownIcon>
            )}
          </>
        )}
      </HeaderLabel>
    </Box>
  );
}

export default TableSortHeader;
