import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Tick from "../../assets/images/tick.svg";
import Minus from "../../assets/images/minus.svg";

interface Props {
  value: boolean;
  disabled?: boolean;
  readonly?: boolean;
}

function ActiveTick(props: Props) {
  const { disabled = false, readonly = false, ...restProps } = props;
  const [value, setValue] = useState(() => {
    return props.value;
  });

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const changeValue = () => {
    if (props.disabled || props.readonly) return;
    setValue(!value);
  };

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      data-testid={value ? "hd_only" : "non-hd"}
    >
      <img src={value ? Tick : Minus} />
    </Box>
  );
  return <></>;
}

export default ActiveTick;
