import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import * as yup from "yup";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PrimaryButton } from "../styles/PrimaryButton";
import { RootState, useAppDispatch } from "../store/store";
import CustomLoader from "../components/custom-loader/custom-loader";
import { registerUser } from "../store/userSlice";
import { useSelector } from "react-redux";
import { GeneralToast } from "../utils/toasts";

let TopBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props: any) => `1px solid ${props.theme.colors.lightGray}`};
`;

let ModalContainer = styled(Box)({
  backgroundColor: "white",
});

let FormContainer = styled.form({
  height: "100%",
  width: "100%",
});

interface Props {
  onClose: any;
  line?: any;
}

let EMAIL_REGX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const addUserValidationSchema = yup.object({
  email: yup
    .string()
    .matches(EMAIL_REGX, "Invalid email address")
    .required("Required")
    .label("User email address"),
  first_name: yup.string().required("Required").label("First name"),
  last_name: yup.string().required("Required").label("Last Name"),
  role: yup.string().required("Required").label("Role"),
});
export const AddUserModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [userRoles, setUserRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const enumObject = useSelector((state: RootState) => state.core.enumObject);
  const [defaultValue, setDefaultValue] = useState<any>({
    first_name: "",
    email: "",
    last_name: "",
    role: "",
  });
  const {
    getValues,
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValue;
    }, [defaultValue]),
    resolver: yupResolver(addUserValidationSchema),
  });

  useEffect(() => {
    if (!defaultValue) return;
    reset(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    let roles: any = [];
    enumObject["user_roles"].forEach((x: any) => {
      roles.push({ id: x.value, label: x.label });
    });
    setUserRoles(roles);
  }, [enumObject]);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    let result: any = await dispatch(registerUser(data));
    if (registerUser.fulfilled.match(result)) {
      setIsLoading(false);
      onClose({ status: 1 });
      GeneralToast.success("User has been registered");
    } else {
      setIsLoading(false);
      GeneralToast.error(
        result?.payload?.data?.message ?? "Problem during registering user"
      );
    }
  };

  const onClose = (payload: any) => {
    props.onClose(payload || {});
  };
  return (
    <Dialog open={true}>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <ModalContainer sx={{ p: 3 }}>
          <TopBox
            sx={{
              pb: 2,
            }}
          >
            <div>
              <Typography variant="h5">Add User</Typography>
              <Typography variant="subtitle2">Setup new user</Typography>
            </div>
            <div></div>
          </TopBox>
          <DialogContent sx={{ position: "relative" }}>
            <Box>
              {isLoading && (
                <Grid
                  container
                  sx={{
                    backgroundColor: "rgba(255,255,255,0.5)",
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    zIndex: 10,
                  }}
                >
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <CustomLoader />
                    </Box>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                      <TextField
                        sx={{ width: "100%" }}
                        {...field}
                        inputProps={{
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                        error={!!errors.hasOwnProperty("email")}
                        helperText={
                          errors?.email?.message
                            ? `${errors?.email?.message}`
                            : null
                        }
                        label="Email"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="first_name"
                    render={({ field }) => (
                      <TextField
                        sx={{ width: "100%" }}
                        {...field}
                        inputProps={{
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                        error={!!errors.hasOwnProperty("first_name")}
                        helperText={
                          errors?.first_name?.message
                            ? `${errors?.first_name?.message}`
                            : null
                        }
                        label="First Name"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="last_name"
                    render={({ field }) => (
                      <TextField
                        sx={{ width: "100%" }}
                        {...field}
                        inputProps={{
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                        error={!!errors.hasOwnProperty("last_name")}
                        helperText={
                          errors?.last_name?.message
                            ? `${errors?.last_name?.message}`
                            : null
                        }
                        label="Last Name"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="role"
                    render={({ field }) => (
                      <FormControl
                        sx={{ width: "100%" }}
                        error={!!errors?.role?.message}
                      >
                        <InputLabel id="role-select-label">Role</InputLabel>
                        <Select
                          labelId="role-select-label"
                          id="role-select-helper"
                          value={field.value}
                          label="Role"
                          onChange={field.onChange}
                        >
                          {userRoles.map((x: any) => {
                            return (
                              <MenuItem key={x.id} value={x.id}>
                                {x.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {!!errors?.role?.message && (
                          <FormHelperText>
                            {errors.role.message as string}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          {/*<DialogActions>*/}
          <Box display={"flex"} justifyContent={"space-between"} sx={{ mt: 4 }}>
            <Box
              sx={{ width: "33%" }}
              display={"flex"}
              justifyContent={"flex-start"}
            >
              <Button onClick={onClose}>Go Back</Button>
            </Box>
            <Box
              sx={{ width: "33%" }}
              display={"flex"}
              justifyContent={"center"}
            >
              <PrimaryButton type="submit" disabled={isLoading}>
                Continue
              </PrimaryButton>
            </Box>
            <Box
              sx={{ width: "33%" }}
              display={"flex"}
              justifyContent={"flex-end"}
            ></Box>
          </Box>
          {/*</DialogActions>*/}
        </ModalContainer>
      </FormContainer>
    </Dialog>
  );
};
export default AddUserModal;
