import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import CheckIcon from "@mui/icons-material/Check";

let CheckboxContainer = styled(Box)<any>`
  width: 20px;
  height: 20px;
  background-color: ${(props: any) => (props.disabled ? "#EFEFEF" : "white")};
  border: 1px solid #6e6972;
  border-radius: 5px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  box-shadow: 0 4px 2px #f0f0f0;
`;

interface Props {
  value: boolean;
  disabled?: boolean;
  readonly?: boolean;
  dataCustomAction?: string;
  handleOnChange?: any;
}

function CustomCheckbox(props: Props) {
  const { disabled = false, readonly = false, ...restProps } = props;
  const [value, setValue] = useState(() => {
    return props.value;
  });

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const changeValue = () => {
    if (props.disabled || props.readonly) return;
    setValue(!value);
  };

  useEffect(() => {
    if (props.handleOnChange) {
      props.handleOnChange(value);
    }
  }, [value]);

  return (
    <>
      <CheckboxContainer
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        onClick={changeValue}
        disabled={props.disabled}
        data-custom-action={props.dataCustomAction || ""}
        data-readonly={!!props.readonly}
      >
        {value ? (
          <CheckIcon
            sx={{ fontSize: "15px", pointerEvents: "none" }}
            color={"primary"}
          />
        ) : (
          <></>
        )}
      </CheckboxContainer>
    </>
  );
}

export default CustomCheckbox;
