import { Box, Button, Menu, MenuItem } from "@mui/material";
import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";
import MainLogo from "../../assets/images/enru-logo.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import { msalInstance } from "../../authConfig";
// import AccountCircle from "@mui/icons-material/AccountCircle";

let LogoContainer = styled.img({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // backgroundColor: 'green',
  height: "36px",
  // width: "200px",
  objectFit: "contain",
  cursor: "pointer",
  // margin: "50px 0",
});

let NavbarComponent = styled.div({
  width: "100%",
  height: "70px",
  backgroundColor: "white",
  boxShadow: "0px 0px 7px 2px #c8c8c8",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: "0",
  zIndex: 1,
});

let NavbarContainer = styled(Box)({
  maxWidth: "1200px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexGrow: 1,
});

function Navbar() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/landing-page");
  };

  const handleLogoutClick = useCallback(() => {
    msalInstance
      .logoutRedirect({
        postLogoutRedirectUri: `${window.location.href}`,
      })
      .then(
        (data) => {
          localStorage.removeItem("user");
          localStorage.removeItem("accessToken");
        },
        (error) => {}
      );
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <NavbarComponent>
      <NavbarContainer>
        <Box mx={2}>
          <LogoContainer onClick={onClick} src={MainLogo}></LogoContainer>
        </Box>
        <Box mx={2}>
          <Box component={"div"}>
            <Box>
              <Button
                data-testid={"top-menu_menu_button"}
                type={"button"}
                sx={{ minWidth: "40px", color: "gray" }}
                onClick={handleClick}
                size="small"
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <AccountCircleIcon />
              </Button>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                data-testid={"top-menu_logout_button"}
                onClick={handleLogoutClick}
              >
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </NavbarContainer>
    </NavbarComponent>
  );
}

export default Navbar;
