import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import { useEffect } from "react";
import { getEnums, getTenantDetails } from "../../store/coreSlice";
import { useIsAuthenticated } from "@azure/msal-react";

let MainContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#F1F1F1",
  height: "100vh",
});

let ContentContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  overflow: "scroll",
});

export const ContentContainerInner = styled.div({
  flexGrow: 1,
  maxWidth: "1200px",
  width: "100%",
  position: "relative",
});

function MainLayout() {
  const initialized = useSelector((state: RootState) => state.app.initialized);
  const enumsStatus = useSelector((state: RootState) => state.core.enumsStatus);
  const tenantDetailsStatus = useSelector(
    (state: RootState) => state.core.tenantDetailsStatus
  );
  const dispatch = useAppDispatch();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getEnums());
      dispatch(getTenantDetails({}));
    }
  }, [isAuthenticated]);

  return initialized &&
    enumsStatus === "fetched" &&
    tenantDetailsStatus === "fetched" ? (
    <MainContainer>
      <Navbar></Navbar>
      <ContentContainer>
        {/*<ContentContainerInner>*/}
        <Outlet />
        {/*</ContentContainerInner>*/}
      </ContentContainer>
    </MainContainer>
  ) : (
    <></>
  );
}

export default MainLayout;
