import styled from "@emotion/styled";
import { Button, ButtonProps } from "@mui/material";

//@ts-ignore
export const BorderButton = styled(Button)<ButtonProps>((props: any) => ({
  borderRadius: 0,
  color: props.theme.colors.link,
  border: `1px solid ${props.theme.colors.link}`,
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "transparent",
  },
  boxShadow: "none",
  textTransform: "none",
}));
