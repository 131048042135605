import React, { useEffect } from "react";
import "./App.scss";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import SignInPage from "./views/sign-in/sign-in";
import MainLayout from "./views/main-layout/main-layout";
import OverviewPage from "./views/overview/overview";
import AuthGuard from "./guards/AuthGuard";
import UnauthGuard from "./guards/UnauthGuard";
import ModalWrapper from "./components/modal-wrapper/modal-wrapper";
import SummaryPage from "./views/summary/summary";
import LineConfigurationPage from "./views/line-configuration/line-configuration";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsersPage from "./views/users/users";
import LandingPage from "./views/landing-page/landing-page";
import UnusedPage from "./views/unused/unused";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route
          path="/sign-in"
          element={<UnauthGuard component={<SignInPage />}></UnauthGuard>}
        />
        <Route
          path="/"
          element={<AuthGuard component={<MainLayout />}></AuthGuard>}
        >
          <Route path="landing-page" index element={<LandingPage />} />
          <Route path="overview" index element={<OverviewPage />} />
          <Route path="unused-files" index element={<UnusedPage />} />
          <Route path="summary" element={<SummaryPage />} />
          <Route
            path="line-configuration"
            element={<LineConfigurationPage />}
          />
          <Route path="users" element={<UsersPage />} />
          <Route path="/" element={<Navigate to={"/landing-page"} />} />
        </Route>
      </Routes>
      <ModalWrapper />
    </BrowserRouter>
  );
}

export default App;
