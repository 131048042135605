import { alpha, Box, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";
import { useDropzone } from "react-dropzone";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

let DropdownInput = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

let UploadBox = styled(Box)((props: any) => ({
  backgroundColor: "#f2f9f8",
  borderRadius: "2px",
  // border: `1px solid ${alpha(props.theme.palette.text.primary, 0.2)}`,
  border: "1px solid #C4C4C4",
}));

interface Props {
  onDrop: any;
  onClear: any;
  testId?: string;
  // status: string | undefined;
}

function Dropzone(props: Props) {
  const [uploadedFile, setUploadedFile] = useState(null);

  const onDrop = useCallback((acceptedFiles: any) => {
    // Do something with the files
    setUploadedFile(acceptedFiles);
    props.onDrop(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const removeFile = (e: any) => {
    e.stopPropagation();
    setUploadedFile(null);
    props.onClear();
  };

  return (
    <>
      <UploadBox
        sx={{
          opacity: isDragActive ? 0.7 : 1,
          position: "relative",
          cursor: !uploadedFile ? "pointer" : "cursor",
        }}
        justifyContent={"center"}
        data-testid={props.testId ? `${props.testId}_dropzone_area` : ""}
      >
        <div {...getRootProps()}>
          <input
            multiple={false}
            {...getInputProps()}
            data-testid={
              props.testId ? `${props.testId}_dropzone_area_input` : ""
            }
          />
          {isDragActive ? (
            <Box
              sx={{ p: 3 }}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <FileUploadOutlinedIcon style={{ color: "#5A7772" }} />
              <Typography variant={"subtitle2"} sx={{ color: "#5A7772" }}>
                Drop the file here...
              </Typography>
            </Box>
          ) : (
            <Box sx={{ p: 3 }}>
              {!uploadedFile && (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <FileUploadOutlinedIcon style={{ color: "#5A7772" }} />
                  <Typography variant={"subtitle2"} sx={{ color: "#5A7772" }}>
                    Drag file or <b>browse</b> for a file to upload
                  </Typography>
                </Box>
              )}
              {!!uploadedFile && (
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography variant={"subtitle2"} sx={{ color: "#5A7772" }}>
                    Uploaded <b>{uploadedFile && uploadedFile[0]["name"]}</b>
                  </Typography>
                  <HighlightOffIcon
                    onClick={removeFile}
                    sx={{
                      color: "#5A7772",
                      fontSize: 15,
                      p: "5px",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
        </div>
      </UploadBox>
    </>
  );
}

export default Dropzone;
