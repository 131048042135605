import { RootState } from "../../store/store";
import { useSelector } from "react-redux";

interface Props {}

function ModalWrapper(props: Props) {
  const modalContent = useSelector(
    (state: RootState) => state.utils.modalContent
  );
  return <>{!!modalContent && modalContent}</>;
}

export default ModalWrapper;
