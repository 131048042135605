import styled from "@emotion/styled";
import { Button, ButtonProps } from "@mui/material";

//@ts-ignore
export const PrimaryButton = styled(Button)<ButtonProps>((props: any) => ({
  borderRadius: 0,
  color: "white",
  border: `1px solid ${props.theme.colors.primary}`,
  backgroundColor: props.theme.colors.primary,
  "&:hover": {
    backgroundColor: props.theme.colors.primary,
  },
  boxShadow: "none",
  textTransform: "initial",
}));
