import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../utils/requests";
import { UserModel } from "../models/UserModel";

export interface UserState {
  users: any;
  userListCount: number;
  loadingUserListStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
  // modalContent: any;
  loadingDeleteUserListStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
  loadingRegisterUserStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
  loggedUser: UserModel | null;
}

const initialState: UserState = {
  users: [],
  userListCount: 0,
  loadingUserListStatus: "idle",
  loadingDeleteUserListStatus: "idle",
  loadingRegisterUserStatus: "idle",
  loggedUser: null,
};

export const getUserList = createAsyncThunk(
  "users/getList",
  async (data: any, thunkAPI) => {
    const response = await api.get(`v2/client/users`, {
      params: data.queryParams,
    });
    return response.data;
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (data: any, thunkAPI) => {
    const response = await api.delete(`v2/client/users/${data.id}`, data);
    return response.data;
  }
);

export const registerUser = createAsyncThunk(
  "users/registerUser",
  async (data: any, thunkAPI) => {
    try {
      const response = await api.post(`v2/client/users/register`, data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoggedUserData: (state, action) => {
      state.loggedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserList.pending, (state) => {
      state.userListCount = 0;
      state.users = [];
      state.loadingUserListStatus = "loading";
    });
    builder.addCase(getUserList.fulfilled, (state, action) => {
      state.users = [...action.payload.data];
      state.userListCount = action.payload.total_count;
      state.loadingUserListStatus = "fetched";
    });
    builder.addCase(getUserList.rejected, (state) => {
      state.users = [];
      state.userListCount = 0;
      state.loadingUserListStatus = "failed";
    });
    builder.addCase(deleteUser.pending, (state) => {
      state.loadingDeleteUserListStatus = "loading";
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.loadingDeleteUserListStatus = "fetched";
    });
    builder.addCase(deleteUser.rejected, (state) => {
      state.loadingDeleteUserListStatus = "failed";
    });
    builder.addCase(registerUser.pending, (state) => {
      state.loadingRegisterUserStatus = "loading";
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.loadingRegisterUserStatus = "fetched";
    });
    builder.addCase(registerUser.rejected, (state) => {
      state.loadingRegisterUserStatus = "failed";
    });
  },
});

// Action creators are generated for each case reducer function
export const { setLoggedUserData } = userSlice.actions;

export default userSlice.reducer;
