import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { summaryColumns } from "./summaryConfig";
import { RootState, useAppDispatch } from "../../store/store";
import { PageContainer } from "../../styles/PageContainer";
import { PrimaryButton } from "../../styles/PrimaryButton";
import {
  getSummaryList,
  updateSummaryPerAddressFilename,
  updateSummaryValidationStatus,
} from "../../store/summarySlice";
import { useSelector } from "react-redux";
import { patchJob, postSetAttrs } from "../../store/jobSlice";
import CustomLoader from "../../components/custom-loader/custom-loader";
import { GeneralToast } from "../../utils/toasts";
import { getCoMailFacilitySlimList } from "../../store/coMailFacilitySlice";
import { JobSummaryModel } from "../../models/JobSummaryModel";
import { PaginatorModel } from "../../models/PaginatorModel";
import CustomSummaryTable from "../../components/custom-summary-table/custom-summary-table";

let TopBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props: any) => `1px solid ${props.theme.colors.lightGray}`};
`;

let ContentContainerInner = styled.div({
  flexGrow: 1,
  maxWidth: "1800px",
  width: "100%",
  position: "relative",
});

function SummaryPage() {
  const summary = useSelector((state: RootState) => state.summary.summary);
  const patchJobsStatus = useSelector(
    (state: RootState) => state.job.patchJobsStatus
  );
  const getCoMailFacilitiesSlimStatus = useSelector(
    (state: RootState) => state.coMailFacility.getCoMailFacilitiesSlimStatus
  );
  const [data, setData] = useState<Array<any>>([]);
  const [pageQueryParams, setPageQueryParams] = useState<PaginatorModel | null>(
    null
  );
  const columns = useMemo(() => summaryColumns, []);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pendingPromises = useRef(0);

  useEffect(() => {
    setData(summary);
  }, [summary]);

  useEffect(() => {
    let bct_filename = sessionStorage.getItem("storedBCTFileName") || null;
    if (bct_filename) {
      let jobPk = sessionStorage.getItem("storedJobPk") || null;
      dispatch(getCoMailFacilitySlimList()).then((result) => {
        if (getCoMailFacilitySlimList.fulfilled.match(result)) {
          dispatch(
            getSummaryList({
              payload: { pk: jobPk },
            })
          ).then((result2) => {
            result2.payload.forEach((x: any, index: number) => {
              dispatch(
                updateSummaryPerAddressFilename({
                  fileName: x.file_name,
                  field: "co_mail_facilities",
                  value: result.payload,
                })
              );
            });
          });
        } else {
          GeneralToast.error("Problem with Co-Mail Facility list");
          navigate("/overview");
        }
      });
    } else {
      GeneralToast.error("Problem with reading BCT file name");
      navigate("/overview");
    }
    dispatch(updateSummaryValidationStatus(null));
    return () => {
      sessionStorage.removeItem("storedBCTFileName");
      sessionStorage.removeItem("storedJobName");
      sessionStorage.removeItem("storedJobNotes");
      sessionStorage.removeItem("storedJobPk");
    };
  }, []);

  const handleRowClick = (e: any, cell: any) => {
    e.preventDefault();
    e.stopPropagation();
    const customAttribute: string | null =
      e.target.getAttribute("data-custom-action");
    const readonlyAttribute: boolean | null =
      e.target.getAttribute("data-readonly") === "true";
    if (!customAttribute || readonlyAttribute) return;
  };

  const confirmJobs = async () => {
    if (
      summary
        .flatMap((obj: any) => obj.versions)
        .filter(
          (x: JobSummaryModel) =>
            !x.hasOwnProperty("co_mail_facilities") ||
            !x?.co_mail_facilities?.length
        ).length
    ) {
      GeneralToast.error(
        "Each version must have at least one facility assigned!"
      );
      dispatch(updateSummaryValidationStatus(true));
      return;
    }
    if (pendingPromises.current > 0) return;
    let jobPk: string | null = sessionStorage.getItem("storedJobPk") || null;
    let payload: { pk?: string; name?: string; notes?: string } = {
      pk: jobPk || "",
    };
    let jobName: string | null =
      sessionStorage.getItem("storedJobName") || null;
    if (jobName) {
      payload["name"] = jobName;
    }
    let jobNotes: string | null =
      sessionStorage.getItem("storedJobNotes") || null;
    if (jobNotes) {
      payload["notes"] = jobNotes;
    }
    let setAttrsPayload = summary
      .flatMap((obj: any) => obj.versions)
      .map(
        ({
          co_mail_facilities,
          scheduled,
          is_hd_only,
          cr_bundle_protection_enabled,
          scf_pallet_protection_enabled,
          adc_ndc_pallet_protection_enabled,
          pk,
          add_a_name_amount,
        }: JobSummaryModel) => {
          return {
            scheduled,
            is_hd_only,
            cr_bundle_protection_enabled,
            scf_pallet_protection_enabled,
            adc_ndc_pallet_protection_enabled,
            pk: pk,
            add_a_name_amount,
            co_mail_facilities_pks: co_mail_facilities?.length
              ? co_mail_facilities.map((x: any) => x.pk)
              : [],
          };
        }
      );

    let result: any = await dispatch(
      postSetAttrs({ pk: jobPk, payload: setAttrsPayload })
    );
    if (postSetAttrs.fulfilled.match(result)) {
      let result: any = await dispatch(patchJob(payload));
      if (patchJob.fulfilled.match(result)) {
        navigate("/overview");
        GeneralToast.success("Job has been created");
        sessionStorage.removeItem("storedBCTFileName");
        sessionStorage.removeItem("storedJobName");
        sessionStorage.removeItem("storedJobPk");
        sessionStorage.removeItem("storedJobNotes");
      } else {
        GeneralToast.error(
          result?.payload?.response?.data?.message ??
            "Something went wrong. Please try again later"
        );
      }
    } else {
      GeneralToast.error(
        result?.payload?.response?.data?.message ??
          "Problem during validation data"
      );
    }
  };

  const onCancel = () => {
    navigate("/overview");
  };

  return (
    <ContentContainerInner>
      <PageContainer sx={{ my: 4, mx: 2, p: 3 }}>
        <TopBox
          sx={{
            pb: 2,
          }}
        >
          <div>
            <Typography variant="h5">Summary</Typography>
            <Typography variant="subtitle2">
              View all recent pools and take actions
            </Typography>
          </div>
        </TopBox>

        {getCoMailFacilitiesSlimStatus === "fetched" && (
          <>
            {data.map((version: any) => (
              <Box sx={{ overflow: "auto", pb: 4 }}>
                <CustomSummaryTable
                  testId={"summary-list"}
                  data={version.versions}
                  columnConfig={columns}
                  queryParams={pageQueryParams}
                  handleRowClick={handleRowClick}
                  updateParams={() => {}}
                  hidePagination={true}
                  totalItems={data.length}
                ></CustomSummaryTable>
              </Box>
            ))}
          </>
        )}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button data-testid={"summary_cancel_button"} onClick={onCancel}>
            Cancel
          </Button>
          <Box sx={{ width: "50px" }}></Box>
          <PrimaryButton
            data-testid={"summary_optimize_button"}
            type={"button"}
            sx={{}}
            onClick={confirmJobs}
            disabled={
              patchJobsStatus === "loading" || pendingPromises.current > 0
            }
          >
            {patchJobsStatus === "loading" && (
              <Box sx={{ mr: 3 }}>
                <CustomLoader size={20} />
              </Box>
            )}
            Optimize
          </PrimaryButton>
        </Box>
      </PageContainer>
    </ContentContainerInner>
  );
}

export default SummaryPage;
