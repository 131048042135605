import { Unstable_NumberInput as NumberInput } from "@mui/base/Unstable_NumberInput";
import { styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { numberInputClasses } from "@mui/base";

interface Props {
  value?: number;
  initialValue: number;
  min?: number;
  step?: number;
  max?: number;
  handleOnChange: any;
  disabled?: boolean;
}

function CustomNumberInput(props: Props) {
  const { step = 1, ...restProps } = props;
  const [value, setValue] = useState<any>(() => {
    return 0;
  });
  const [rerender, setRerender] = useState<any>(new Date().getTime());

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onChange = (event: any, value: any) => {
    const valueExist = (val: number | null | undefined) => {
      return !!(val !== null && val !== undefined);
    };

    let newVal = 0;
    // @ts-ignore
    if (value < props.value) {
      if (valueExist(props.min)) {
        // @ts-ignore
        newVal = Math.max(value, props.min);
        if (value === -1 && props.min === 0) {
          setRerender(new Date().getTime());
        }
      } else {
        newVal = value || 0;
      }
    } else {
      if (valueExist(props.max)) {
        // @ts-ignore
        newVal = Math.min(value, props.max);
      } else {
        newVal = value || 0;
      }
    }

    setValue(newVal);
  };

  const onBlur = (a: any) => {
    setTimeout(() => {
      props.handleOnChange(value);
    });
    setRerender(new Date().getTime());
  };

  return (
    <>
      <NumberInput
        key={rerender}
        slots={{
          root: StyledInputRoot,
          input: StyledInputElement,
          incrementButton: StyledButton,
          decrementButton: StyledButton,
        }}
        slotProps={{
          incrementButton: {
            children: "▴",
          },
          decrementButton: {
            children: "▾",
          },
        }}
        disabled={props.disabled}
        onBlur={onBlur}
        onInputChange={(x: any) => {
          console.log("x", x);
          onChange({}, parseInt(x.target.value));
        }}
        step={props.step || 1}
        min={props.min || undefined}
        max={props.max || undefined}
        value={value}
        onChange={onChange}
      />
    </>
  );
}

export default CustomNumberInput;

const StyledInputRoot = styled("div")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  border-radius: 5px;
  background: white;
  border: 1px solid #5C7772;
  display: grid;
  grid-template-columns: 1fr 19px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  column-gap: 8px;
  padding: 4px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
  
  .base--disabled {
    opacity:0.4;
  }
`
);

const StyledInputElement = styled("input")(
  ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  grid-column: 1/2;
  grid-row: 1/3;
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 12px;
  outline: 0;
`
);

const StyledButton = styled("button")(
  ({ theme }) => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  width: 19px;
  height: 19px;
  font-family: system-ui, sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  box-sizing: border-box;
  border: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    cursor: pointer;
  }

  &.${numberInputClasses.incrementButton} {
    grid-column: 2/3;
    grid-row: 1/2;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid;
    border-bottom: 0;
    border-color: #DAE2ED;
    background: #F3F6F9;
    color: #1C2025;

    &:hover {
      cursor: pointer;
      color: #FFF;
      background: ${theme.palette.primary.main};
      border-color: ${theme.palette.primary.main};
    }
  }

  &.${numberInputClasses.decrementButton} {
    grid-column: 2/3;
    grid-row: 2/3;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid;
    border-color: #DAE2ED;
    background: #F3F6F9;
    color: #1C2025;
  }

  &:hover {
    cursor: pointer;
    color: #FFF;
    background: ${theme.palette.primary.main};
    border-color: ${theme.palette.primary.main};
  }

  & .arrow {
    transform: translateY(-1px);
  }

  & .arrow {
    transform: translateY(-1px);
  }
`
);
