import { ButtonBase, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import useDebounce from "../../hooks/useDebounce";

// import AccountCircle from "@mui/icons-material/AccountCircle";

interface Props {
  callback: any;
  initialValue: string;
  testId?: string;
}

function SearchFilter(props: Props) {
  const [initialRender, setInitialRender] = useState(true);
  const [value, setValue] = useState<string>(() => {
    return props.initialValue || "";
  });
  // const debounce = useDebounce();
  const onChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setValue(e.target.value);
  };

  useDebounce(
    () => {
      if (initialRender) {
        setInitialRender(false);
      } else {
        props.callback(value);
      }
    },
    [value],
    800
  );

  return (
    <>
      <TextField
        value={value}
        onChange={onChange}
        label={`Search`}
        placeholder={"Search"}
        InputLabelProps={{
          shrink: true,
        }}
        data-testid={props.testId}
        inputProps={{ ["data-testid"]: `${props.testId}_search_input` }}
        InputProps={{
          endAdornment:
            value && value.length ? (
              <ButtonBase onClick={() => setValue("")}>
                <CloseIcon />
              </ButtonBase>
            ) : (
              <SearchIcon />
            ),
        }}
      />
    </>
  );
}

export default SearchFilter;
