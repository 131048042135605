import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../utils/requests";

export interface CoMailFacilityState {
  coMailFacilities: any;
  coMailFacilitiesSlim: any;
  coMailFacilityListCount: number;
  getCoMailFacilitiesStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
  getCoMailFacilitiesSlimStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
  patchCoMailFacilityStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
  postCoMailFacilityStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
  getCoMailFacilityLineConfigStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
  postCoMailFacilityLineConfigStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
  patchCoMailFacilityLineConfigStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
  deleteCoMailFacilityLineConfigStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
  patchCoMailFacilityProcessingSequenceStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
}

const initialState: CoMailFacilityState = {
  coMailFacilities: [],
  coMailFacilitiesSlim: [],
  coMailFacilityListCount: 0,
  getCoMailFacilitiesStatus: "idle",
  getCoMailFacilitiesSlimStatus: "idle",
  patchCoMailFacilityStatus: "idle",
  postCoMailFacilityStatus: "idle",
  getCoMailFacilityLineConfigStatus: "idle",
  postCoMailFacilityLineConfigStatus: "idle",
  patchCoMailFacilityLineConfigStatus: "idle",
  deleteCoMailFacilityLineConfigStatus: "idle",
  patchCoMailFacilityProcessingSequenceStatus: "idle",
};

export const getCoMailFacilityList = createAsyncThunk(
  "coMailFacilities/get",
  async (data: any, thunkAPI) => {
    const response = await api.get(
      `v2/client/co-mail-facilities/in-processing-sequence`,
      {
        params: data.queryParams,
      }
    );
    return response.data;
  }
);

export const getCoMailFacilitySlimList = createAsyncThunk(
  "coMailFacilitiesSlim/get",
  async (thunkAPI) => {
    const response = await api.get(`v2/client/co-mail-facilities/slim`);
    return response.data;
  }
);

export const patchCoMailFacility = createAsyncThunk(
  "coMailFacilities/patch",
  async (payload: any, thunkAPI) => {
    // return response.data;

    try {
      const response = await api.patch(
        `v2/client/co-mail-facilities/${payload.pk}`,
        payload.data
      );
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);

export const postCoMailFacility = createAsyncThunk(
  "coMailFacilities/post",
  async (data: any, thunkAPI) => {
    try {
      const response = await api.post(
        `v2/client/co-mail-facilities`,
        data.payload
      );
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);
export const patchCoMailFacilityProcessingSequence = createAsyncThunk(
  "coMailFacilitiesProcessingSequence/patch",
  async (data: any, thunkAPI) => {
    try {
      const response = await api.patch(
        `v2/client/co-mail-facilities/set-processing-sequence`,
        data.payload
      );
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteCoMailFacility = createAsyncThunk(
  "coMailFacilities/delete",
  async (payload: any, thunkAPI) => {
    try {
      const response = await api.delete(
        `v2/client/co-mail-facilities/${payload.pk}`
      );
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCoMailFacilityLineConfig = createAsyncThunk(
  "coMailFacilities/lineConfig/get",
  async (payload: any, thunkAPI) => {
    try {
      const response = await api.get(
        `v2/client/co-mail-facilities/${payload.facilityPk}/line-configs/${payload.lineConfigPk}`
      );
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);
export const postCoMailFacilityLineConfig = createAsyncThunk(
  "coMailFacilities/lineConfig/post",
  async (payload: any, thunkAPI) => {
    try {
      const response = await api.post(
        `v2/client/co-mail-facilities/${payload.coMailFacilityId}/line-configs`,
        { ...payload.data }
      );
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);

export const patchCoMailFacilityLineConfig = createAsyncThunk(
  "coMailFacilities/lineConfig/patch",
  async (payload: any, thunkAPI) => {
    try {
      const response = await api.patch(
        `v2/client/co-mail-facilities/${payload.facilityPk}/line-configs/${payload.lineConfigPk}`,
        payload.data
      );
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteCoMailFacilityLineConfig = createAsyncThunk(
  "coMailFacilities/lineConfig/delete",
  async (payload: any, thunkAPI) => {
    try {
      const response = await api.delete(
        `v2/client/co-mail-facilities/${payload.facilityPk}/line-configs/${payload.lineConfigPk}`,
        payload.data
      );
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);

export const coMailFacilitySlice = createSlice({
  name: "coMailFacility",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCoMailFacilityList.pending, (state) => {
      state.coMailFacilityListCount = 0;
      state.coMailFacilities = [];
      state.getCoMailFacilitiesStatus = "loading";
    });
    builder.addCase(getCoMailFacilityList.fulfilled, (state, action) => {
      state.coMailFacilities = [...action.payload];
      // state.coMailFacilityListCount = pay.total_count;
      state.getCoMailFacilitiesStatus = "fetched";
    });
    builder.addCase(getCoMailFacilityList.rejected, (state) => {
      state.coMailFacilityListCount = 0;
      state.coMailFacilities = [];
      state.getCoMailFacilitiesStatus = "failed";
    });
    builder.addCase(getCoMailFacilitySlimList.pending, (state) => {
      state.coMailFacilitiesSlim = [];
      state.getCoMailFacilitiesSlimStatus = "loading";
    });
    builder.addCase(getCoMailFacilitySlimList.fulfilled, (state, action) => {
      state.coMailFacilitiesSlim = [...action.payload];
      state.getCoMailFacilitiesSlimStatus = "fetched";
    });
    builder.addCase(getCoMailFacilitySlimList.rejected, (state) => {
      state.coMailFacilitiesSlim = [];
      state.getCoMailFacilitiesSlimStatus = "failed";
    });
    builder.addCase(patchCoMailFacility.pending, (state) => {
      state.patchCoMailFacilityStatus = "loading";
    });
    builder.addCase(patchCoMailFacility.fulfilled, (state, action) => {
      // state.coMailFacilitiesStats = { ...action.payload };
      state.patchCoMailFacilityStatus = "fetched";
    });
    builder.addCase(patchCoMailFacility.rejected, (state, action) => {
      state.patchCoMailFacilityStatus = "failed";
    });
    builder.addCase(postCoMailFacility.pending, (state) => {
      state.postCoMailFacilityStatus = "loading";
    });
    builder.addCase(postCoMailFacility.fulfilled, (state, action) => {
      state.postCoMailFacilityStatus = "fetched";
    });
    builder.addCase(postCoMailFacility.rejected, (state, action) => {
      state.postCoMailFacilityStatus = "failed";
    });
    builder.addCase(patchCoMailFacilityProcessingSequence.pending, (state) => {
      state.patchCoMailFacilityProcessingSequenceStatus = "loading";
    });
    builder.addCase(
      patchCoMailFacilityProcessingSequence.fulfilled,
      (state, action) => {
        state.patchCoMailFacilityProcessingSequenceStatus = "fetched";
      }
    );
    builder.addCase(
      patchCoMailFacilityProcessingSequence.rejected,
      (state, action) => {
        state.patchCoMailFacilityProcessingSequenceStatus = "failed";
      }
    );
    builder.addCase(getCoMailFacilityLineConfig.pending, (state) => {
      state.getCoMailFacilityLineConfigStatus = "loading";
    });
    builder.addCase(getCoMailFacilityLineConfig.fulfilled, (state, action) => {
      state.getCoMailFacilityLineConfigStatus = "fetched";
    });
    builder.addCase(getCoMailFacilityLineConfig.rejected, (state, action) => {
      state.getCoMailFacilityLineConfigStatus = "failed";
    });
    builder.addCase(postCoMailFacilityLineConfig.pending, (state) => {
      state.postCoMailFacilityLineConfigStatus = "loading";
    });
    builder.addCase(postCoMailFacilityLineConfig.fulfilled, (state, action) => {
      state.postCoMailFacilityLineConfigStatus = "fetched";
    });
    builder.addCase(postCoMailFacilityLineConfig.rejected, (state, action) => {
      state.postCoMailFacilityLineConfigStatus = "failed";
    });
    builder.addCase(patchCoMailFacilityLineConfig.pending, (state) => {
      state.patchCoMailFacilityLineConfigStatus = "loading";
    });
    builder.addCase(
      patchCoMailFacilityLineConfig.fulfilled,
      (state, action) => {
        state.patchCoMailFacilityLineConfigStatus = "fetched";
      }
    );
    builder.addCase(patchCoMailFacilityLineConfig.rejected, (state, action) => {
      state.patchCoMailFacilityLineConfigStatus = "failed";
    });
    builder.addCase(deleteCoMailFacilityLineConfig.pending, (state) => {
      state.deleteCoMailFacilityLineConfigStatus = "loading";
    });
    builder.addCase(
      deleteCoMailFacilityLineConfig.fulfilled,
      (state, action) => {
        state.deleteCoMailFacilityLineConfigStatus = "fetched";
      }
    );
    builder.addCase(
      deleteCoMailFacilityLineConfig.rejected,
      (state, action) => {
        state.deleteCoMailFacilityLineConfigStatus = "failed";
      }
    );
  },
});

// Action creators are generated for each case reducer function
export const {} = coMailFacilitySlice.actions;

export default coMailFacilitySlice.reducer;
