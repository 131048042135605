import { configureStore } from "@reduxjs/toolkit";
import utilsReducer from "./utilsSlice";
import appReducer from "./appSlice";
import jobReducer from "./jobSlice";
import lineReducer from "./lineSlice";
import configurationReducer from "./configurationSlice";
import summaryReducer from "./summarySlice";
import userReducer from "./userSlice";
import coreReducer from "./coreSlice";
import coMailFacilityReducer from "./coMailFacilitySlice";
import addressesReducer from "./addressesSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

export const store = configureStore({
  reducer: {
    utils: utilsReducer,
    app: appReducer,
    job: jobReducer,
    line: lineReducer,
    configuration: configurationReducer,
    summary: summaryReducer,
    user: userReducer,
    core: coreReducer,
    coMailFacility: coMailFacilityReducer,
    address: addressesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type RootState = ReturnType<typeof store.getState>;
