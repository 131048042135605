import {
  Box,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useState } from "react";
import styled from "@emotion/styled";

let Dot = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${(props: any) => props.color};
  border-radius: 100px;
`;

interface Props {
  pageSize: number | undefined;
  callback: any;
  testId?: string;
}

function PageSize({ pageSize = 10, callback, testId }: Props) {
  const [value, setValue] = useState<number>(() => {
    return pageSize || 10;
  });

  const handleChange = (event: SelectChangeEvent) => {
    setValue(+event.target.value);
    callback(+event.target.value);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      {/*<InputLabel id="demo-select-small-label">Show</InputLabel>*/}
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={value + ""}
        onChange={handleChange}
        data-testid={`${testId}_page-size_select`}
      >
        <MenuItem value={10} data-testid={`${testId}_page-size_option-10`}>
          Show 10
        </MenuItem>
        <MenuItem value={20} data-testid={`${testId}_page-size_option-20`}>
          Show 20
        </MenuItem>
        <MenuItem value={30} data-testid={`${testId}_page-size_option-30`}>
          Show 30
        </MenuItem>
        <MenuItem value={40} data-testid={`${testId}_page-size_option-40`}>
          Show 40
        </MenuItem>
        <MenuItem value={50} data-testid={`${testId}_page-size_option-50`}>
          Show 50
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default PageSize;
