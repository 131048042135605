import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CustomCheckbox from "../custom-checkbox/custom-checkbox";
import Select, { components, InputProps } from "react-select";

interface Props {
  value: Array<any>;
  disabled?: boolean;
  readonly?: boolean;
  selectAllOptionAvailable?: boolean;
  options: Array<any>;
  handleOnChange: any;
  hasError?: boolean;
}

const CustomInput = (inputProps: InputProps) => (
  <components.Input
    {...inputProps}
    data-testid={"overview_postal-effective-date_dropdown"}
  />
);

const OptionSelected = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <Box display={"flex"} alignItems={"center"} flexDirection={"row"}>
          <CustomCheckbox value={true}></CustomCheckbox>
          <Box sx={{ ml: 1 }}>
            {" "}
            <label>{props.label}</label>
          </Box>
        </Box>
      </components.Option>
    </div>
  );
};

const Option = (props: any) => {
  return (
    <Box
      sx={{ borderBottom: props.data.pk === "*" ? "1px solid #A8CBC6" : "" }}
    >
      <components.Option {...props}>
        <Box display={"flex"} alignItems={"center"} flexDirection={"row"}>
          <CustomCheckbox value={props.isSelected}></CustomCheckbox>
          <Box sx={{ ml: 1 }}>
            {" "}
            <label>{props.label}</label>
          </Box>
        </Box>
      </components.Option>
    </Box>
  );
};

function CustomMultiSelect(props: Props) {
  const refSelect = useRef<any>(null);
  const {
    disabled = false,
    readonly = false,
    selectAllOptionAvailable = false,
    hasError = false,
    ...restProps
  } = props;
  const [selectedValue, setSelectedValue] = useState<any>(props.value || []);
  const [showAllOptionSelected, setShowAllOptionSelected] =
    useState<boolean>(false);
  const [availableOptions, setAvailableOptions] = useState<any>(() => {
    if (props.selectAllOptionAvailable)
      return [{ name: "Select All", pk: "*" }, ...props.options];
    return [...props.options];
  });

  useEffect(() => {
    props.handleOnChange(selectedValue);
  }, [selectedValue]);

  if (showAllOptionSelected) {
    // Dropdown with all options selected
    return (
      <Select
        classNames={{ control: (state) => (props.hasError ? "has-error" : "") }}
        menuPosition="fixed"
        isDisabled={props.disabled}
        components={{ Input: CustomInput, Option: OptionSelected }}
        options={availableOptions}
        onChange={(item: any, v: any) => {
          if (v.action === "clear" || v?.option?.pk === "*") {
            setSelectedValue([]);
            setShowAllOptionSelected(false);
          } else {
            let currentItem: any = null;
            if (
              v.action === "select-option" ||
              v.action === "deselect-option"
            ) {
              currentItem = v.option;
            } else {
              currentItem = v.removedValue;
            }
            setShowAllOptionSelected(false);
            setSelectedValue([
              ...props.options.filter((x) => x.pk !== currentItem.pk),
            ]);
          }
        }}
        value={selectedValue}
        getOptionLabel={(option: any) => `${option.name}`}
        getOptionValue={(option: any) => `${option["pk"]}`}
        placeholder={"Facility"}
        isMulti={true}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        styles={ReactSelectStyles}
      />
    );
  } else {
    return (
      <Select
        ref={refSelect}
        classNames={{ control: (state) => (props.hasError ? "has-error" : "") }}
        menuPosition="fixed"
        isDisabled={props.disabled}
        components={{ Input: CustomInput, Option: Option }}
        options={availableOptions}
        onChange={(item: any, v: any) => {
          if (v.action === "clear") {
            setSelectedValue([]);
            return;
          }
          if (props.selectAllOptionAvailable && v?.option?.pk === "*") {
            refSelect.current.blur();
            setShowAllOptionSelected(!showAllOptionSelected);
            setSelectedValue([...props.options]);
          } else {
            let currentItem: any = null;
            if (
              v.action === "select-option" ||
              v.action === "deselect-option"
            ) {
              currentItem = v.option;
            } else {
              currentItem = v.removedValue;
            }
            let idx = selectedValue.findIndex(
              (x: any) => x.pk === currentItem.pk
            );
            if (idx !== -1) {
              let arr = [...selectedValue];
              arr.splice(idx, 1);
              let newArray = [...arr];
              setSelectedValue(newArray);
            } else {
              let newArray = [...selectedValue, currentItem];
              setSelectedValue(newArray);
              if (newArray.length === props.options.length)
                setShowAllOptionSelected(true);
            }
          }
        }}
        value={selectedValue}
        getOptionLabel={(option: any) => `${option.name}`}
        getOptionValue={(option: any) => `${option["pk"]}`}
        placeholder={"Facility"}
        isMulti={true}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        styles={ReactSelectStyles}
      />
    );
  }
}

const ReactSelectStyles = {
  control: (baseStyles: any, state: any) => {
    return {
      ...baseStyles,
      width: 200,
      borderColor:
        state.selectProps.classNames.control() === "has-error"
          ? "red"
          : "#5C7772",
      borderRadius: "5px",
      boxShadow: "none",
      ":hover": {
        borderColor:
          state.selectProps.classNames.control() === "has-error"
            ? "red"
            : "#5C7772",
      },
    };
  },
  option: (provided: any, state: any) => ({
    ...provided,
    // color: state.isSelected ? "white" : "#5C7772",
    color: "#5C7772",
    //   backgroundColor: state.isSelected ? "rgba(189,197,209,.3)" : "red",
    backgroundColor: state.isSelected ? "white" : "inherit",
    "&:hover": {
      backgroundColor: state.isSelected ? "white" : "rgba(92,119,114,0.32)",
    },
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    color: "#5C7772",
  }),
  menuList: (provided: any, state: any) => ({
    border: "1px solid #5C7772",
    borderRadius: "5px",
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: "#5C7772",
    fontFamily: "Roboto,sans-serif",
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: 1.75,
  }),
  placeholder: (baseStyles: any, state: any) => ({
    ...baseStyles,
    color: "rgba(92,119,114,0.68)",
    fontFamily: "Roboto,sans-serif",
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: 1.75,
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    color: state.isFocused ? "#5C7772" : "rgba(92,119,114,0.32)",
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
  }),
  clearIndicator: (base: any, state: any) => ({
    ...base,
    color: state.isFocused ? "#5C7772" : "rgba(92,119,114,0.32)",
  }),
};

export default CustomMultiSelect;
