import styled from "@emotion/styled";
import {
  Box,
  keyframes,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import CustomTable from "../../components/custom-table/custom-table";
import { overviewColumns } from "./overviewConfig";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import { setModalContent } from "../../store/utilsSlice";
import CreatePool from "../../modals/create-pool-modal";
import { PrimaryButton } from "../../styles/PrimaryButton";
import { BorderButton } from "../../styles/BorderButton";
import { PageContainer } from "../../styles/PageContainer";
import { MoreHoriz } from "@mui/icons-material";
import {
  getJobList,
  setCancelJobStatus,
  setRestartJobStatus,
} from "../../store/jobSlice";
import Select, { InputProps, components } from "react-select";
import {
  getPostageEffectiveDates,
  patchPostageEffectiveDates,
} from "../../store/coreSlice";
import { GeneralToast } from "../../utils/toasts";
import { ContentContainerInner } from "../main-layout/main-layout";
import PageSize from "../../components/page-size/page-size";
import { JobModel } from "../../models/JobType";
import { PaginatorModel } from "../../models/PaginatorModel";
import { parse } from "date-fns";
import { format } from "date-fns";

const CustomInput = (inputProps: InputProps) => (
  <components.Input
    {...inputProps}
    data-testid={"overview_postal-effective-date_dropdown"}
  />
);

let TopBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props: any) => `1px solid ${props.theme.colors.lightGray}`};
`;

function OverviewPage() {
  const jobs: Array<JobModel> = useSelector(
    (state: RootState) => state.job.jobs
  );
  const jobListCount: number = useSelector(
    (state: RootState) => state.job.jobListCount
  );
  const loadingJobListStatus = useSelector(
    (state: RootState) => state.job.loadingJobListStatus
  );
  const cancelJobStatus = useSelector(
    (state: RootState) => state.job.cancelJobStatus
  );
  const restartJobStatus = useSelector(
    (state: RootState) => state.job.restartJobStatus
  );
  const postageEffectiveDates = useSelector(
    (state: RootState) => state.core.postageEffectiveDates
  );
  const tenantDetails = useSelector(
    (state: RootState) => state.core.tenantDetails
  );
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Array<JobModel>>(() => []);
  const [selectedPostageEffectiveDate, setSelectedPostageEffectiveDate] =
    useState(null);
  const [pageQueryParams, setPageQueryParams] = useState<any>(null);
  const columns = useMemo(() => overviewColumns, []);
  const location = useLocation();
  const navigate = useNavigate();
  const modalContent = useSelector(
    (state: RootState) => state.utils.modalContent
  );
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const loadPostageEffectiveDates = () => {
    dispatch(getPostageEffectiveDates({}));
  };

  useEffect(() => {
    loadPostageEffectiveDates();
  }, []);

  useEffect(() => {
    if (jobs && jobs.length) {
      setData(jobs);
    }
  }, [jobs]);

  useEffect(() => {
    if (cancelJobStatus === "fetched") {
      dispatch(setCancelJobStatus("idle"));
      dispatch(getJobList({ queryParams: queryString.parse(location.search) }));
    }
  }, [cancelJobStatus]);

  useEffect(() => {
    if (restartJobStatus === "fetched") {
      dispatch(setRestartJobStatus("idle"));
      dispatch(getJobList({ queryParams: queryString.parse(location.search) }));
    }
  }, [restartJobStatus]);

  useEffect(() => {
    if (loadingJobListStatus === "loading") {
      if (!isLoading) {
        setIsLoading(true);
      }
    } else {
      if (isLoading) {
        setIsLoading(false);
      }
    }
  }, [loadingJobListStatus]);

  const initialParams: PaginatorModel = {
    page: 1,
    page_size: 10,
    search: "",
    order_by: "-start_date_time",
  };

  useEffect(() => {
    let p: any = {};
    const queryParams = new URLSearchParams(location.search);
    Object.keys(initialParams).forEach((key) => {
      let val;
      if (!queryParams.get(key)) {
        val = initialParams[key];
      } else {
        val = queryParams.get(key);
      }
      if (val !== undefined && val !== null) {
        if (["page", "page_size"].indexOf(key) !== -1) {
          p[key] = +val;
        } else if (["search", "order_by"].indexOf(key) !== -1) {
          p[key] = `${val}`;
        }
      }
    });
    setPageQueryParams(p);
  }, []);

  useEffect(() => {
    if (!pageQueryParams) return;
    navigate(
      { pathname: ".", search: queryString.stringify(pageQueryParams) },
      { replace: true }
    );
  }, [pageQueryParams]);

  useEffect(() => {
    if (!location.search) return;
    dispatch(getJobList({ queryParams: queryString.parse(location.search) }));
  }, [location.search]);

  const handleRowClick = (e: any, cell: any) => {
    e.preventDefault();
    e.stopPropagation();
    const customAttribute: string | null =
      e.target.getAttribute("data-custom-action");
    if (!customAttribute) return;
    if (customAttribute === "download") {
    } else if (customAttribute === "cancel") {
    } else {
    }
  };

  useEffect(() => {
    findSelectedPostageEffectiveDate();
  }, [tenantDetails, postageEffectiveDates]);

  const findSelectedPostageEffectiveDate = () => {
    if (!postageEffectiveDates || !tenantDetails) return;
    let found = postageEffectiveDates.find(
      (x: any) => x.pk === tenantDetails.postage_effective_date_pk
    );
    setSelectedPostageEffectiveDate(found);
  };

  const onSearchChanged = (text: string) => {
    setPageQueryParams({
      page: 1,
      page_size: pageQueryParams.page_size,
      search: text,
      order_by: pageQueryParams.order_by,
    });
  };

  const onPageSizeChanged = (pageSize: number) => {
    setPageQueryParams({
      page: 1,
      page_size: pageSize,
      search: pageQueryParams.search,
      order_by: pageQueryParams.order_by,
    });
  };

  const onUpdateChanged = (data: any) => {
    setPageQueryParams({
      page: data.page,
      order_by: data.order_by,
      page_size: pageQueryParams.page_size,
      search: pageQueryParams.search,
    });
  };

  const onClosePoolModal = (data: any) => {
    dispatch(setModalContent(null));
    if (data && data.success) {
      navigate("/summary");
    }
  };

  const createPool = () => {
    if (!modalContent) {
      dispatch(setModalContent(<CreatePool onClose={onClosePoolModal} />));
    }
  };

  const goToConfiguration = () => {
    navigate("/line-configuration");
  };

  const goToUnusedFiles = () => {
    navigate("/unused-files");
  };

  const handleChangePostageEffectiveDate = (data: any) => {
    dispatch(
      patchPostageEffectiveDates({
        queryParams: {
          postage_effective_date_pk: data?.pk ?? null,
        },
      })
    ).then((response) => {
      if (patchPostageEffectiveDates.fulfilled.match(response)) {
        setSelectedPostageEffectiveDate(data);
        GeneralToast.success("Postage Effective Date has been changed");
      } else {
        GeneralToast.error("Problem occurred. Please try again later");
        findSelectedPostageEffectiveDate();
      }
    });
  };

  return (
    <ContentContainerInner>
      <PageContainer sx={{ my: 4, mx: 2, p: 3 }}>
        <TopBox
          sx={{
            pb: 2,
          }}
        >
          <div>
            <Typography variant="h5">Overview</Typography>
            <Typography variant="subtitle2">
              View all recent pools and take actions
            </Typography>
          </div>
          <Box component={"div"} sx={{ display: { xs: "none", sm: "flex" } }}>
            <BorderButton
              type={"button"}
              sx={{ mr: 2 }}
              onClick={goToUnusedFiles}
              data-testid={"overview_unused-file_button"}
            >
              Unused File
            </BorderButton>
            {postageEffectiveDates && tenantDetails && (
              <Box sx={{ mr: 2 }}>
                <Select
                  components={{ Input: CustomInput }}
                  options={postageEffectiveDates}
                  onChange={(e) => {
                    handleChangePostageEffectiveDate(e);
                  }}
                  placeholder={"Post. Eff. Date"}
                  value={selectedPostageEffectiveDate}
                  getOptionLabel={(option: any) => {
                    let label = format(
                      parse(
                        option["is_effective_from"],
                        "yyyy-MM-dd",
                        new Date()
                      ),
                      "LLLL yyyy"
                    );
                    return label;
                  }}
                  getOptionValue={(option: any) =>
                    `${option["is_effective_from"]}`
                  }
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      width: 200,
                      borderColor: "#5C7772",
                      borderRadius: 0,
                      boxShadow: "none",
                      ":hover": {
                        borderColor: "#5C7772",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      color: state.isSelected ? "white" : "#5C7772",
                      //   backgroundColor: state.isSelected ? "rgba(189,197,209,.3)" : "red",
                      backgroundColor: state.isSelected ? "#5C7772" : "inherit",
                      "&:hover": {
                        backgroundColor: state.isSelected
                          ? "#5C7772"
                          : "rgba(92,119,114,0.32)",
                      },
                    }),
                    input: (provided, state) => ({
                      ...provided,
                      color: "#5C7772",
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      color: "#5C7772",
                      fontFamily: "Roboto,sans-serif",
                      fontWeight: 500,
                      fontSize: "0.875rem",
                      lineHeight: 1.75,
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      color: "rgba(92,119,114,0.68)",
                      fontFamily: "Roboto,sans-serif",
                      fontWeight: 500,
                      fontSize: "0.875rem",
                      lineHeight: 1.75,
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      color: state.isFocused
                        ? "#5C7772"
                        : "rgba(92,119,114,0.32)",
                    }),
                    indicatorSeparator: (base) => ({
                      ...base,
                    }),
                    clearIndicator: (base, state) => ({
                      ...base,
                      color: state.isFocused
                        ? "#5C7772"
                        : "rgba(92,119,114,0.32)",
                    }),
                  }}
                />
              </Box>
            )}
            <BorderButton
              type={"button"}
              sx={{ mr: 2 }}
              onClick={goToConfiguration}
              data-testid={"overview_configuration_button"}
            >
              Configuration
            </BorderButton>
            <PrimaryButton
              type={"button"}
              sx={{}}
              onClick={createPool}
              data-testid={"overview_create-pool-and-optimizer_button"}
            >
              Create Pool & Optimize
            </PrimaryButton>
          </Box>
          <Box component={"div"} sx={{ display: { sm: "none" } }}>
            <Box>
              <Tooltip title={"Actions"}>
                <BorderButton
                  type={"button"}
                  sx={{ mr: 2, minWidth: "40px" }}
                  onClick={handleClick}
                  size="small"
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <MoreHoriz />
                </BorderButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={() => {
                  navigate("/line-configuration");
                }}
              >
                Configuration
              </MenuItem>
              <MenuItem onClick={createPool}>Create Pool & Optimize</MenuItem>
            </Menu>
          </Box>
        </TopBox>

        <Box sx={{ my: 4 }} display={"flex"} justifyContent={"space-between"}>
          <Box></Box>
          {pageQueryParams && (
            <PageSize
              pageSize={pageQueryParams.page_size}
              callback={onPageSizeChanged}
            />
          )}
        </Box>

        <Box sx={{ overflow: "auto", pb: 4 }}>
          <CustomTable
            testId={"overview-list"}
            isLoading={isLoading}
            data={data}
            columnConfig={columns}
            queryParams={pageQueryParams}
            handleRowClick={handleRowClick}
            updateParams={onUpdateChanged}
            totalItems={jobListCount}
          ></CustomTable>
        </Box>
      </PageContainer>
    </ContentContainerInner>
  );
}

export default OverviewPage;
