import { Box } from "@mui/material";
import React, { useState } from "react";
import styled from "@emotion/styled";

let Dot = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${(props: any) => props.color};
  border-radius: 100px;
`;

interface Props {
  label: string | undefined;
  color: string | undefined;
}

function StatusDot({ label = "Unknown", color = "#9c9c9c" }: Props) {
  return (
    <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
      <Dot color={color} />
      <Box component={"span"} sx={{ ml: 1 }}>
        {label}
      </Box>
    </Box>
  );
}

export default StatusDot;
