import { createColumnHelper } from "@tanstack/react-table";
import { Box } from "@mui/material";
import { CustomLink } from "../../styles/CustomLink";
import { NumericFormat } from "react-number-format";
import ActiveTick from "../../components/active-tick/active-tick";

type Record = {
  grouped_lines: number;
  pockets: number;
  min_quantity_per_line: number;
  max_quantity_all_lines: number;
  is_hd_only: boolean;
  edit: any;
  remove: any;
};

const columnHelper = createColumnHelper<Record>();
export const lineOverviewColumns = [
  columnHelper.accessor("grouped_lines", {
    cell: (info) => (
      <>
        <NumericFormat
          value={info.getValue()}
          thousandSeparator={","}
          displayType={"text"}
        />
      </>
    ),
    // header: () => <Box sx={{ textAlign: "center" }}>Job #</Box>,
    footer: (info) => info.column.id,
    minSize: 200,
    meta: {
      label: "Grouped Lines",
    },
    enableSorting: true,
  }),
  columnHelper.accessor("pockets", {
    cell: (info) => (
      <>
        <NumericFormat
          value={info.getValue()}
          thousandSeparator={","}
          displayType={"text"}
        />
      </>
    ),
    // header: () => <Box sx={{ textAlign: "center" }}>Job #</Box>,
    footer: (info) => info.column.id,
    minSize: 200,
    meta: {
      label: "Pockets",
    },
    enableSorting: true,
  }),
  columnHelper.accessor((row) => row.min_quantity_per_line, {
    id: "min_quantity_per_line",
    cell: (info) => {
      return (
        <>
          <NumericFormat
            value={info.getValue()}
            thousandSeparator={","}
            displayType={"text"}
          />
        </>
      );
    },
    // header: () => <span>End Date</span>,
    footer: (info) => info.column.id,
    minSize: 180,
    meta: {
      label: "Min Quantity Per Line",
    },
    enableSorting: true,
  }),
  columnHelper.accessor((row) => row.max_quantity_all_lines, {
    id: "max_quantity_all_lines",
    cell: (info) => {
      return (
        <>
          <NumericFormat
            value={info.getValue()}
            thousandSeparator={","}
            displayType={"text"}
          />
        </>
      );
    },
    // header: () => <span>End Date</span>,
    footer: (info) => info.column.id,
    minSize: 180,
    meta: {
      label: "Max Quantity All Lines",
    },
    enableSorting: true,
  }),
  columnHelper.accessor((row) => row.is_hd_only, {
    id: "is_hd_only",
    cell: (info) => {
      return (
        <>
          <ActiveTick value={info.getValue()} />
        </>
      );
    },
    // header: () => <span>End Date</span>,
    footer: (info) => info.column.id,
    minSize: 180,
    meta: {
      label: "HD Only",
    },
    enableSorting: true,
  }),
  columnHelper.accessor((row) => row.edit, {
    id: "edit",
    cell: (info) => {
      return (
        <Box display={"flex"} flexDirection={"column"}>
          <CustomLink data-custom-action={"edit"}>Edit</CustomLink>
        </Box>
      );
    },
    // header: () => <span>Actions</span>,
    footer: (info) => info.column.id,
    minSize: 50,
    meta: {
      label: "",
    },
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.remove, {
    id: "remove",
    cell: (info) => {
      return (
        <Box display={"flex"} flexDirection={"column"}>
          <CustomLink data-custom-action={"remove"}>Remove</CustomLink>
        </Box>
      );
    },
    // header: () => <span>Actions</span>,
    footer: (info) => info.column.id,
    minSize: 50,
    meta: {
      label: "",
    },
    enableSorting: false,
  }),
];
