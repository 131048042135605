import {
  Box,
  Tooltip,
  Typography,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { cancelJob } from "../../store/jobSlice";
import { useAppDispatch } from "../../store/store";
import CustomLoader from "../custom-loader/custom-loader";
import { CustomLink } from "../../styles/CustomLink";
import { GeneralToast } from "../../utils/toasts";
import { setModalContent } from "../../store/utilsSlice";
import ConfirmationModal from "../../modals/confirmation-modal";
import TrashIcon from "../../assets/images/trash-icon.svg";

let ButtonContainer = styled(Box)<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#566C68",
    color: "#F4FAF9",
    maxWidth: 220,
    borderRadius: 2,
    fontSize: 12,
    borderWidth: 1,
    borderColor: "red",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#566C68",
  },
}));

interface Props {
  job: any;
}

function CancelJob(props: Props) {
  const [isProgress, setIsProgress] = useState(false);
  const dispatch = useAppDispatch();

  const onConfirmationHandler = async (payload: any) => {
    if (payload?.status) {
      setIsProgress(true);
      if (!props.job || !props.job.pk) return;
      dispatch(setModalContent(null));

      let result: any = await dispatch(cancelJob({ jobPk: props.job.pk }));
      if (cancelJob.fulfilled.match(result)) {
        GeneralToast.success("Job has been canceled");
      } else {
        GeneralToast.error(
          result?.payload?.response?.data?.message ??
            "Problem during canceling job. Please, try again later"
        );
      }
      setIsProgress(false);
    } else {
      dispatch(setModalContent(null));
      setIsProgress(false);
    }
  };

  const cancelJobHandler = async () => {
    if (isProgress) return;
    dispatch(
      setModalContent(
        <ConfirmationModal
          text={"Do you really want to cancel this job?"}
          header={"Confirmation"}
          onClose={(result: any) => onConfirmationHandler(result)}
        />
      )
    );
  };

  return (
    <>
      <CustomLink>
        <ButtonContainer onClick={cancelJobHandler}>
          <HtmlTooltip
            arrow
            title={
              <React.Fragment>
                <Typography color="inherit">Cancel Optimization</Typography>
              </React.Fragment>
            }
            placement={"top"}
          >
            {isProgress ? (
              <Box sx={{ width: 20 }}>
                <CustomLoader size={15} />
              </Box>
            ) : (
              <img src={TrashIcon} />
            )}
          </HtmlTooltip>
        </ButtonContainer>
      </CustomLink>
    </>
  );
}

export default CancelJob;
