import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonProps,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchFilter from "../../components/search-filter/search-filter";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import PageSize from "../../components/page-size/page-size";
import CustomTable from "../../components/custom-table/custom-table";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import {
  setModalContent,
  setModalContentIsProgress,
} from "../../store/utilsSlice";
import { PrimaryButton } from "../../styles/PrimaryButton";
import { BorderButton } from "../../styles/BorderButton";
import { PageContainer } from "../../styles/PageContainer";
import { userColumns } from "./userConfig";
import { MoreHoriz } from "@mui/icons-material";
import ConfirmationModal from "../../modals/confirmation-modal";
import { toast } from "react-toastify";
import AddUserModal from "../../modals/add-user-modal";
import { deleteUser, getUserList } from "../../store/userSlice";
import { ContentContainerInner } from "../main-layout/main-layout";
import { PaginatorModel } from "../../models/PaginatorModel";
import { GeneralToast } from "../../utils/toasts";

let TopBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props: any) => `1px solid ${props.theme.colors.lightGray}`};
`;

function UsersPage() {
  const users = useSelector((state: RootState) => state.user.users);
  const role = useSelector((state: RootState) => state?.user?.loggedUser?.role);
  const userListCount = useSelector(
    (state: RootState) => state.user.userListCount
  );
  const loadingUserListStatus = useSelector(
    (state: RootState) => state.user.loadingUserListStatus
  );
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<any>(false);
  const [pageQueryParams, setPageQueryParams] = useState<any>(null);
  const columns = useMemo(() => userColumns, []);
  const location = useLocation();
  const navigate = useNavigate();
  const modalContent = useSelector(
    (state: RootState) => state.utils.modalContent
  );
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setData(users);
  }, [users]);

  const initialParams: PaginatorModel = {
    page: 1,
    page_size: 10,
    search: "",
    order_by: "email",
  };

  useEffect(() => {
    let p: any = {};
    const queryParams = new URLSearchParams(location.search);
    Object.keys(initialParams).forEach((key) => {
      let val;
      if (!queryParams.get(key)) {
        val = initialParams[key];
      } else {
        val = queryParams.get(key);
      }
      if (val !== undefined && val !== null) {
        if (["page", "page_size"].indexOf(key) !== -1) {
          p[key] = +val;
        } else if (["search", "order_by"].indexOf(key) !== -1) {
          p[key] = `${val}`;
        }
      }
    });
    setPageQueryParams(p);
  }, []);

  useEffect(() => {
    if (!pageQueryParams) return;
    navigate(
      { pathname: ".", search: queryString.stringify(pageQueryParams) },
      { replace: true }
    );
  }, [pageQueryParams]);

  useEffect(() => {
    if (!location.search) return;
    dispatch(getUserList({ queryParams: queryString.parse(location.search) }));
  }, [location.search]);

  useEffect(() => {
    if (loadingUserListStatus === "loading") {
      if (!isLoading) {
        setIsLoading(true);
      }
    } else {
      if (isLoading) {
        setIsLoading(false);
      }
    }
  }, [loadingUserListStatus]);

  const onDeleteCallback = async (data: any, cell: any) => {
    if (data.status) {
      dispatch(setModalContentIsProgress(true));
      let result = await dispatch(
        deleteUser({ id: cell.row.original.user_id })
      );
      if (deleteUser.fulfilled.match(result)) {
        dispatch(setModalContentIsProgress(false));
        dispatch(
          getUserList({ queryParams: queryString.parse(location.search) })
        );
        GeneralToast.success("User has been deleted");
      } else {
        dispatch(setModalContentIsProgress(false));
        GeneralToast.error("Problem during deleting user");
      }
    }
    setShowConfirmationModal(false);
  };

  const handleRowClick = async (e: any, cell: any) => {
    e.preventDefault();
    e.stopPropagation();
    const customAttribute: string | null =
      e.target.getAttribute("data-custom-action");
    if (!customAttribute) return;

    if (customAttribute === "remove") {
      setShowConfirmationModal(
        <ConfirmationModal
          text={"Do you really want to remove this user?"}
          header={"Confirmation"}
          onClose={(result: any) => onDeleteCallback(result, cell)}
        />
      );
    }
  };

  const onSearchChanged = (text: string) => {
    setPageQueryParams({
      page: 1,
      page_size: pageQueryParams.page_size,
      search: text,
      order_by: pageQueryParams.order_by,
    });
  };

  const onPageSizeChanged = (pageSize: number) => {
    setPageQueryParams({
      page: 1,
      page_size: pageSize,
      search: pageQueryParams.search,
      order_by: pageQueryParams.order_by,
    });
  };

  const onUpdateChanged = (data: PaginatorModel) => {
    setPageQueryParams({
      page: data.page,
      order_by: data.order_by,
      page_size: pageQueryParams.page_size,
      search: pageQueryParams.search,
    });
  };

  const onCloseAddUserModal = (payload: null | { status: number }) => {
    setTimeout(() => {
      dispatch(setModalContent(null));
    });
    if (payload?.status) {
      dispatch(
        getUserList({ queryParams: queryString.parse(location.search) })
      );
    }
  };

  const addUser = () => {
    if (!modalContent) {
      dispatch(setModalContent(<AddUserModal onClose={onCloseAddUserModal} />));
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goBack = () => {
    navigate("/landing-page");
  };

  return (
    <ContentContainerInner>
      <PageContainer sx={{ my: 4, mx: 2, p: 3 }}>
        <TopBox
          sx={{
            pb: 2,
          }}
        >
          <div>
            <Typography variant="h5">Users</Typography>
            <Typography variant="subtitle2">Manage CMS Users</Typography>
          </div>
          <Box component={"div"} sx={{ display: { xs: "none", sm: "block" } }}>
            <div>
              {role === "admin" && (
                <PrimaryButton type={"button"} sx={{}} onClick={addUser}>
                  Add User
                </PrimaryButton>
              )}
            </div>
          </Box>

          <Box component={"div"} sx={{ display: { sm: "none" } }}>
            <Box>
              <Tooltip title={"Actions"}>
                <BorderButton
                  type={"button"}
                  sx={{ mr: 2, minWidth: "40px" }}
                  onClick={handleClick}
                  size="small"
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <MoreHoriz />
                </BorderButton>
              </Tooltip>
            </Box>
          </Box>
        </TopBox>

        <Box sx={{ my: 4 }} display={"flex"} justifyContent={"space-between"}>
          {pageQueryParams && (
            <SearchFilter
              callback={onSearchChanged}
              initialValue={pageQueryParams.search}
              testId={"user-list"}
            />
          )}
          {pageQueryParams && (
            <PageSize
              pageSize={pageQueryParams.page_size}
              callback={onPageSizeChanged}
              testId={"user-list"}
            />
          )}
        </Box>
        <Box sx={{ overflow: "auto", pb: 4 }}>
          <CustomTable
            testId={"user-list"}
            isLoading={isLoading}
            data={data}
            columnConfig={columns}
            queryParams={pageQueryParams}
            handleRowClick={handleRowClick}
            updateParams={onUpdateChanged}
            totalItems={userListCount}
          ></CustomTable>
        </Box>

        <Box display={"flex"} justifyContent={"space-between"} sx={{ mt: 4 }}>
          <Box
            sx={{ width: "33%" }}
            display={"flex"}
            justifyContent={"flex-start"}
          >
            <Button onClick={goBack}>Go Back</Button>
          </Box>
        </Box>

        {showConfirmationModal}
      </PageContainer>
    </ContentContainerInner>
  );
}

export default UsersPage;
