import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import * as yup from "yup";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Dropzone from "../components/dropzone/dropzone";
import { PrimaryButton } from "../styles/PrimaryButton";
import { BorderButton } from "../styles/BorderButton";
import { useAppDispatch } from "../store/store";
import CustomLoader from "../components/custom-loader/custom-loader";
import { GeneralToast } from "../utils/toasts";
import FileSaver from "file-saver";
import { format } from "date-fns";
import { createJob } from "../store/jobSlice";

let TopBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props: any) => `1px solid ${props.theme.colors.lightGray}`};
`;

let ModalContainer = styled(Box)({
  backgroundColor: "white",
});

let FormContainer = styled.form({
  height: "100%",
  width: "100%",
});

let ErrorBox = styled(Box)((props: any) => ({
  backgroundColor: "error.light",
  borderRadius: "2px",
  border: `1px solid ${props.theme.colors.error}`,
  color: props.theme.colors.error,
  marginTop: "5px",
  marginBottom: "5px",
}));

interface Props {
  onClose: any;
}

const createPoolValidationSchema = yup.object({
  name: yup.string().required("Required"),
  notes: yup.string(),
});

export const CreatePoolModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [uploadFileError, setUploadFileError] = useState<any>(null);
  const [file, setFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [fileWithErrors, setFileWithErrors] = useState<any>(null);
  const [defaultValue, setDefaultValue] = useState<any>({
    name: "",
    notes: "",
  });
  const {
    getValues,
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValue;
    }, [defaultValue]),
    resolver: yupResolver(createPoolValidationSchema),
  });

  useEffect(() => {
    if (!defaultValue) return;
    reset(defaultValue);
  }, [defaultValue]);

  const onSubmit = async (data: any) => {
    if (isProcessing) return;
    setIsProcessing(true);
    if (!file) {
      setUploadFileError({ message: "Please provide file for upload!" });
      setIsProcessing(false);
      return;
    }

    const formData = new FormData();
    // Object.keys(data).forEach((k) => {
    //   formData.append(k, data[k]);
    // });
    // // @ts-ignore
    formData.append("file", file);
    let result: any = await dispatch(createJob(formData));
    if (createJob.fulfilled.match(result)) {
      GeneralToast.success("Pool Setup has been added");
      setIsProcessing(false);
      sessionStorage.setItem("storedBCTFileName", file["name"]);
      sessionStorage.setItem("storedJobPk", result.payload);
      if (data.name) {
        sessionStorage.setItem("storedJobName", data.name);
      }
      if (data.notes) {
        sessionStorage.setItem("storedJobNotes", data.notes);
      }
      props.onClose({ success: true });
    } else {
      if (result?.payload?.response?.data?.details?.errors) {
        let payload = result?.payload?.response?.data?.details?.errors;
        if (payload) {
          if (Object.prototype.toString.call(payload) === "[object Array]") {
            if (payload && payload.length) {
              GeneralToast.error("Problem during data validation");
              let errorsToSave =
                result?.payload?.response?.data?.details?.errors.join("\n");
              var blob = new Blob([errorsToSave], {
                type: "text/plain;charset=utf-8",
              });
              setFileWithErrors(blob);
            }
          } else {
            GeneralToast.error(
              result?.payload?.response?.data?.message ??
                "Problem during data validation"
            );
          }
        }
      } else {
        GeneralToast.error(
          result?.payload?.response?.data?.message ??
            "Problem during file upload"
        );
        setFileWithErrors(null);
      }
      //@ts-ignore
      if (result?.payload) {
        setIsProcessing(false);
        setUploadFileError({
          message: result?.payload?.response?.data?.message?.Error,
        });
      }
    }
  };

  const onClose = (data: any) => {
    props.onClose(data);
  };

  const handleOnDrop = (data: any) => {
    setFile(data[0]);
    setUploadFileError(null);
  };

  const handleOnClear = (data: any) => {
    setFile(null);
    setUploadFileError(null);
  };

  const downloadFileWithErrors = () => {
    if (file && fileWithErrors) {
      FileSaver.saveAs(
        fileWithErrors,
        `${file["name"]}_${format(
          new Date(),
          "MM/dd/yyyy hh:mm a"
        ).toString()}_errors.txt`
      );
    }
  };

  return (
    <Dialog open={true} onClose={() => {}}>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <ModalContainer sx={{ p: 3 }}>
          <TopBox
            sx={{
              pb: 2,
            }}
          >
            <div>
              <Typography variant="h5">Pool Setup</Typography>
              <Typography variant="subtitle2">Set up a new pool run</Typography>
            </div>
          </TopBox>
          <DialogContent>
            {/*<div>{JSON.stringify(errors)}</div>*/}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <TextField
                      sx={{ width: "100%" }}
                      {...field}
                      inputProps={{
                        ["data-testid"]: "add-line-modal_job-id_input",
                        autoComplete: "new-password",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                      error={!!errors.hasOwnProperty("name")}
                      helperText={
                        errors?.name?.message
                          ? `${errors?.name?.message}`
                          : null
                      }
                      label="Job #"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="notes"
                  render={({ field }) => (
                    <TextField
                      sx={{ width: "100%" }}
                      multiline={true}
                      rows={5}
                      {...field}
                      inputProps={{
                        ["data-testid"]: "add-line-modal_notes_input",
                        autoComplete: "new-password",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                      error={!!errors.hasOwnProperty("notes")}
                      helperText={
                        errors?.notes?.message
                          ? `${errors?.notes?.message}`
                          : null
                      }
                      label="Notes"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display={"flex"} alignItems={"center"}>
                  <InfoOutlinedIcon color={"primary"} />{" "}
                  <Box sx={{ px: 1 }}>
                    <Typography variant={"subtitle2"} sx={{ lineHeight: 1 }}>
                      Maximum 140 characters
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Dropzone
                  testId={"add-line-modal"}
                  onDrop={handleOnDrop}
                  onClear={handleOnClear}
                ></Dropzone>
                {uploadFileError && (
                  <ErrorBox>
                    <Box
                      sx={{ px: 1, py: "5px", bgcolor: "error.light" }}
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      // sx={{ opacity: 0.6 }}
                    >
                      <Box display={"flex"}>
                        <Box sx={{ pr: 1 }}>
                          <InfoOutlinedIcon
                            color={"error"}
                            sx={{ fontSize: 12 }}
                          />
                        </Box>
                        <Typography
                          variant={"subtitle2"}
                          sx={{ color: "error.main", fontSize: 12 }}
                        >
                          {
                            "Error occurred, please retry and check the report file."
                          }
                        </Typography>
                      </Box>
                      {fileWithErrors && (
                        <Typography
                          onClick={downloadFileWithErrors}
                          sx={{
                            color: "error.main",
                            fontSize: 12,
                            textAlign: "end",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Download report file
                        </Typography>
                      )}
                    </Box>
                  </ErrorBox>
                )}
              </Grid>
              <Grid item xs={12}>
                <Box display={"flex"} alignItems={"center"}>
                  <InfoOutlinedIcon color={"primary"} />{" "}
                  <Box sx={{ px: 1 }}>
                    <Typography variant={"subtitle2"} sx={{ lineHeight: 1 }}>
                      File format: XLSX/XLS, size file: 5MB
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          {/*<DialogActions>*/}
          <Box display={"flex"} justifyContent={"space-between"} sx={{ mt: 4 }}>
            <Box
              sx={{ width: "33%" }}
              display={"flex"}
              justifyContent={"flex-start"}
            >
              <Button
                data-testid={"add-line-modal_go-back_button"}
                onClick={onClose}
              >
                Go Back
              </Button>
            </Box>
            <Box
              sx={{ width: "33%" }}
              display={"flex"}
              justifyContent={"center"}
            >
              <PrimaryButton
                type="submit"
                data-testid={"add-line-modal_continue_button"}
              >
                {isProcessing && (
                  <Box sx={{ mr: 3 }}>
                    <CustomLoader size={20} />
                  </Box>
                )}
                Continue
              </PrimaryButton>
            </Box>
            <Box
              sx={{ width: "33%" }}
              display={"flex"}
              justifyContent={"flex-end"}
            ></Box>
          </Box>
          {/*</DialogActions>*/}
        </ModalContainer>
      </FormContainer>
    </Dialog>
  );
};
export default CreatePoolModal;
