declare global {
  interface Window {
    env: any;
  }
}

// change with your own variables
type EnvType = {
  REACT_APP_BASE_URL: string;
  REACT_APP_MSAL_CLIENT_ID: string;
  REACT_APP_MSAL_TENANT_NAME: string;
  REACT_APP_MSAL_POLICY_ID: string;
  REACT_APP_B2C_SCOPE: string;
};
export const env: EnvType = { ...process.env, ...window.env };
