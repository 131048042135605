import { createSlice } from "@reduxjs/toolkit";
import { msalInstance } from "../authConfig";

export interface AppState {
  initialized: boolean;
  userProfile: any;
}

const initialState: AppState = {
  initialized: false,
  userProfile: null,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setInitialized: (state, action) => {
      state.initialized = action.payload;
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setInitialized, setUserProfile } = appSlice.actions;

export default appSlice.reducer;
